import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import quoromlogo from "../../assets/logo-quorom.png";

function Header({ toPricing }) {

  const navigate = useNavigate();

  const redirectToSignUp = () => {
    navigate("/auth");
  };

  const redirectToLogin = () => {
    navigate("/auth", {
      state: {
        toLogin: true,
      },
    });
  };

  const scrollToPricing = () => {
    document
      .getElementById("pricing-table")
      .scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (toPricing) {
      scrollToPricing();
    }
  }, [toPricing]);

  return (
    <nav className="bg-white border-gray-200">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="/" className="flex items-center space-x-3">
          <img src={quoromlogo} className="md:h-8 h-12" alt="quorom Logo" />
        </a>
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto"
          id="navbar-cta"
        >
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white ">
            <li>
              <a
                href="#"
                className="block py-2 px-3 md:p-0 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700"
                aria-current="page"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700"
              >
                Features
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 "
              >
                Pricing
              </a>
            </li>
          </ul>
        </div>
        <div className="flex space-x-3 md:space-x-0">
          <div className="hidden md:block">
            <button
              type="button"
              onClick={redirectToLogin}
              className="bg-transparent focus:ring-none focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center"
            >
              Sign In
            </button>
            <button
              type="button"
              onClick={redirectToSignUp}
              className="border-2 border-gray-300 text-[#8934E9] focus:ring-unset focus:outline-none font-medium rounded-[50px] text-sm px-4 py-2 text-center "
            >
              Sign Up
            </button>
          </div>
          <button
            data-collapse-toggle="navbar-cta"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-cta"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Header;
