import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useAppDispatch, useAppSelector } from "../store";
import { getFolders } from "../store/DocumentSlice";
import SpinnerLoading from "./SpinnerLoading";
import { FilesPreview, ImageUpload } from "./Helper";
import { EditorState, Modifier } from "draft-js";
import apiClient from "../utils/axiosInstance";
import { addNewChat } from "../store/finChat";
import useAuth from "../utils/useAuth";
import posthog from "posthog-js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useSocket } from "../context/SocketProvider";
import WebChatModel from "./WebChatModel";
import LinkPreviewGrid from "./LinkPreviewGrid";


const insertMention = (editorState, mention) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  const mentionText = `${mention.text} `;
  const contentStateWithEntity = contentState.createEntity(
    'MENTION',
    'IMMUTABLE',
    { mention }
  );

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const contentStateWithMention = Modifier.replaceText(
    contentState,
    selectionState,
    mentionText,
    null,
    entityKey
  );

  const newEditorState = EditorState.push(
    editorState,
    contentStateWithMention,
    'insert-characters'
  );

  return EditorState.forceSelection(
    newEditorState,
    contentStateWithMention.getSelectionAfter()
  );
};

const MessageEditor = ({
  selectedFiles,
  organization,
  isAnotherspace = false,
  setSelectedFiles,
  uploadingFiles,
  setUploadingFiles,
  tags,
  setTags,
  handleSendMessage,
  isApiCalling,
  setIsApiCalling,
  chatApiCalling,
  setChatApiCalling,
  isLoading,
  mentionState,
  setMentionState,
  taggedFolders,
  setTaggedFolders,
  editorState,
  setEditorState,
  sharedFolders,
  tab,
  isReplying = false,
}) => {
  const { user } = useAuth();
  const { socket } = useSocket();
  const selectedFilesRef = useRef(selectedFiles);
  selectedFilesRef.current = selectedFiles;
  const { folderState, status, chats, userDetailsState } = useAppSelector((state) => ({
    chats: state.finChat.chatWithLimit,
    userDetailsState: state.auth.userDetails,
    folderState: state.document.folders,
    status: state.document.folderStatus,
  }));
  const navigate = useNavigate();
  let folders = [];
  const [detectedLinks, setDetectedLinks] = useState([]);
  const [closedLinks, setClosedLinks] = useState([]);


  const mapFolders = (folderArray) => folderArray?.map(folder => ({
    folderName: folder.folderName,
    folderId: folder.folderId,
  })) || [];

  folders = (() => {
    switch (tab) {
      case "chat":
        return tags.length > 0 ? mapFolders(sharedFolders) : mapFolders(folderState?.folders);
      case "thread":
        if (tags?.length === 0) return mapFolders(folderState?.folders);
        return sharedFolders?.length > 0 ? mapFolders(sharedFolders) : [];
      default:
        return sharedFolders?.length > 0 ? mapFolders(sharedFolders) : [];
    }
  })();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setEditorState((prevState) => {
      const contentState = prevState.getCurrentContent();
      const selection = prevState.getSelection();
      const mentionEntity = contentState.createEntity('MENTION', 'IMMUTABLE', { mention: { name: `@${organization?.chatBotName || "chatbot"}` } });
      const entityKey = mentionEntity.getLastCreatedEntityKey();

      const mentionText = `@${organization?.chatBotName || "chatbot"}`;
      const contentStateWithEntity = Modifier.replaceText(
        contentState,
        selection.merge({
          anchorOffset: 0,
          focusOffset: contentState.getPlainText().length,
        }),
        mentionText,
        null,
        entityKey
      );

      const newEditorState = EditorState.push(prevState, contentStateWithEntity, 'insert-characters');
      return EditorState.moveFocusToEnd(newEditorState);
    });
  }, [organization]);

  useEffect(() => {
    if (!folderState?.folders?.length > 0) {
      dispatch(getFolders());
    }
  }, [dispatch, folderState?.folders?.length]);

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState);
    const content = newEditorState.getCurrentContent();
    const rawText = content.getPlainText();
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const links = rawText.match(urlRegex) || [];
    setDetectedLinks(links);
  };

  const handleReturn = (e) => {
    setDetectedLinks([]);
    if (isAnotherspace) {
      return 'handled';
    }
    const isEnterPress = e && e.key === "Enter" && !e.shiftKey;
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockKey = selectionState.getStartKey();
    const block = contentState.getBlockForKey(blockKey);
    const textBeforeCursor = block.getText().slice(0, selectionState.getStartOffset());

    const lastCharacter = textBeforeCursor.slice(-1);

    if (isEnterPress && lastCharacter === "@") {
      const mentionList = getSuggestions();
      if (mentionList.length > 0) {
        const firstMention = mentionList[0];
        const newEditorState = insertMention(editorState, firstMention);
        const contentState = newEditorState.getCurrentContent();
        const plainText = contentState.getPlainText();

        const newTaggedFolders = folders?.filter((folder) => plainText.includes(`@${folder.folderName}`))
          .map((folder) => folder.folderId);

        setTaggedFolders(newTaggedFolders);
        setEditorState(newEditorState);
        return 'handled';
      }
    } else if (isEnterPress) {
      const contentState = editorState.getCurrentContent();
      const hasText = contentState.getPlainText().trim();
      const hasFiles = selectedFiles.length > 0;

      if (hasText.trim() || hasFiles) {
        const contentState = editorState.getCurrentContent();
        const plainText = contentState.getPlainText();
        const containsAskfinAI = plainText.includes(`@${organization?.chatBotName}`);
        const messageText = plainText.replace(`@${organization?.chatBotName}`, "").trim();
        if (containsAskfinAI && messageText.trim() !== "" && tags?.length === 0 && tab === "chat" && taggedFolders.length === 0) {
          askfinThread(messageText);
          setSelectedFiles([]);
          setEditorState(EditorState.createEmpty());
          return 'handled';
        } else if (messageText.trim() !== "" && tab === "chat") {
          normalThread(messageText, hasFiles, containsAskfinAI);
          setSelectedFiles([]);
          setEditorState(EditorState.createEmpty());
          return 'handled';
        } else if (messageText.trim() !== "" && tab !== "chat") {
          handleSendMessage(editorState);
          setSelectedFiles([]);
          setEditorState(EditorState.createEmpty());
          return 'handled';
        }
      }
    }

    return 'not-handled';
  };

  const askfinThread = async (messageText) => {
    try {
      setChatApiCalling(true);


      let body = {
        query: messageText.replace(`@${organization?.chatBotName}`, "").trim(),
        action: "chat",
        createdAt: new Date().toISOString(),
      };

      const response = await apiClient().post(`/chat`, body);
      dispatch(addNewChat(response.data));

      if (response && chats.length === 0 && user?.email) {
        posthog.identify(user?.email);
        posthog.capture("chat_started", { email: user?.email });
      }

      const threadId = response?.data?.threadId;
      if (threadId) {
        navigate(`/answer-search/${threadId}`);
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    } finally {
      setChatApiCalling(false);
    }
  };

  const normalThread = async (messageText, hasFiles, containsAskfinAI) => {
    try {
      setIsApiCalling(true);

      const taggedMembers = Object.values(
        tags
          .filter((tag) => tag.type === "member")
          .reduce((acc, tag) => {
            acc[tag.id] = tag;
            return acc;
          }, {})
      );
      const taggedTeams = Object.values(
        tags
          .filter((tag) => tag.type === "team")
          .reduce((acc, tag) => {
            acc[tag.id] = tag;
            return acc;
          }, {})
      );
      const tagged = [...taggedMembers, ...taggedTeams];
      const sharedWith = tagged.map((tag) => ({
        tagId: tag.id,
        accountType: tag.type,
      }));

      const messageType = hasFiles
        ? messageText
          ? "text-file"
          : "file"
        : "text";

      const payload = {
        threadId: uuidv4(),
        messageId: uuidv4(),
        isThread: true,
        message: messageText,
        tag: sharedWith,
        createdAt: new Date().toISOString(),
        messageType: messageType,
      };

      if (hasFiles) {
        payload.files = selectedFiles.map((file) => ({
          docS3Loc: file.docS3Loc,
          url: file.url,
          fileName: file.file.name,
        }));
      }

      const tempResponse = {
        threadId: payload.threadId,
        chatName: payload.message,
        createdAt: payload.createdAt,
        message: payload.message,
        senderId: user?.userId,
        senderName: userDetailsState?.name,
        tag: tagged.map((tag) => ({
          tagId: tag.id,
          accountType: tag.type,
          tagName: tag.name,
        })),
        receivers: tagged.map((tag) => ({
          tagId: tag.id,
          accountType: tag.type,
        })),
        messageId: payload.messageId,
        type: payload.type,
        messageType: payload.messageType,
        files: payload.files,
      };

      const tempThreadResponse = {
        ...tempResponse,
        type: payload.type,
      };

      if (containsAskfinAI) {
        tempResponse.isAiChat = true;
        tempResponse.query = payload.message;
        tempResponse.taggedFolders = taggedFolders;
      }

      navigate(`/thread/${tempResponse.threadId}`, {
        state: {
          chat: tempResponse,
        },
      });

      socket.emit("new_main_thread_created", {
        tag: tagged.map((tag) => ({
          tagId: tag.id,
          accountType: tag.type,
        })),
        thread: tempThreadResponse,
      });

      setEditorState(EditorState.createEmpty());
      dispatch(addNewChat(tempResponse));
      await apiClient().post("/thread", payload);
    } catch (error) {
      toast.error(error);
      console.log(error);
    } finally {
      setIsApiCalling(false);
      setTags([]);
    }
  };

  const handleMentionChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    const askFinTagged = plainText.includes(`@${organization?.chatBotName}`);

    const newTaggedFolders = folders?.filter((folder) => plainText.includes(`@${folder.folderName}`))
      .map((folder) => folder.folderId);

    setTaggedFolders(newTaggedFolders);

    setMentionState((prev) => ({
      ...prev,
      showAskFin: !askFinTagged,
      askFinTagged: askFinTagged,
    }));

    getSuggestions(newTaggedFolders);

  };

  const getSuggestions = (currentTaggedFolders) => {
    if (mentionState.showAskFin) {
      return [{ text: organization?.chatBotName, value: organization?.chatBotName }];
    }
    const uniqueFolders = folders?.reduce((acc, current) => {
      const x = acc.find(item => item.folderId === current.folderId);
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);
    return uniqueFolders?.filter((folder) => !currentTaggedFolders?.includes(folder.folderId))
      .map((folder) => ({
        text: folder.folderName,
        value: folder.folderName,
      }));
  };

  return (
    <>
      {(isLoading || status === "loading") && <SpinnerLoading isLoading={true} />}
      {userDetailsState && <div className={`bg-white ${tab !== "chat" && `${!isReplying && "xl:px-24"} lg:px-4 px-2 py-2 lg:py-4`}`}>
        {chatApiCalling && (
          <div className="flex justify-center items-center mb-24">
            <Icon
              icon="eos-icons:loading"
              width="2em"
              height="2em"
              className="text-blue-500"
            />
            <span className="ml-2">Processing your request...</span>
          </div>
        )}
        <div className={`relative border-2 rounded-lg `}>

          <div className="max-h-[300px] min-h-[100px] md:mb-10 mb-[6.5rem]  max-w-[1289px]">
            <FilesPreview
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              uploadingFiles={uploadingFiles}
            />
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName  h-full"
              onEditorStateChange={(editorState) => {
                handleChange(editorState);
                handleMentionChange(editorState);
              }}
              handleReturn={handleReturn}
              mention={{
                separator: " ",
                trigger: "@",
                suggestions: getSuggestions(),
                handleReturn: (suggestion, mentionState) => {
                  if (suggestion) {
                    const newEditorState = mentionState.addMention(suggestion);
                    handleChange(newEditorState);
                    return 'handled';
                  }
                  return 'not-handled';
                },
              }}
              toolbar={{
                options: ["inline", "list", "emoji", "image", "link"],
                inline: {
                  options: ["bold", "italic"],
                  className: "inline-style",
                  bold: { icon: "/bold.svg", className: "bold-button" },
                },
                list: {
                  options: ["unordered", "ordered"],
                  className: "inline-style",
                },
                image: {
                  component: () => (
                    <ImageUpload
                      setSelectedFiles={setSelectedFiles}
                      setUploadingFiles={setUploadingFiles}
                    />
                  ),
                },
                link: {
                  component: () => (
                    <WebChatModel />
                  )
                }
              }}
            />
            {detectedLinks.length > 0 && (
              <LinkPreviewGrid urls={detectedLinks} setUrls={setDetectedLinks} closedLinks={closedLinks} setClosedLinks={setClosedLinks} />
            )}
          </div>
          <div className="flex justify-end items-end px-4 py-2">
            <div className="flex-shrink-0">
              <button
                className={`px-4 py-1 rounded-md ${isAnotherspace
                  ? "text-borderColor cursor-not-allowed"
                  : (!editorState.getCurrentContent().hasText() &&
                    selectedFiles.length === 0) ||
                    isApiCalling
                    ? "text-borderColor cursor-not-allowed"
                    : "text-white"
                  }`}
                style={{
                  backgroundColor: isApiCalling ? "gray" : organization?.secondaryColor
                }}
                disabled={
                  isAnotherspace ||
                  (!editorState.getCurrentContent().hasText() &&
                    selectedFiles.length === 0) ||
                  isApiCalling
                }
                onClick={(e) => {
                  if (!isAnotherspace) {
                    handleReturn({ key: "Enter" });
                  }
                }}
              >
                {
                  isAnotherspace ? <Icon icon="mdi:lock" width="1.5em" height="1.5em" /> : isApiCalling ? (
                    <Icon icon="eos-icons:loading" width="1.5em" height="1.5em" />
                  ) : (
                    <Icon icon="mdi:paper-airplane" width="1.5em" height="1.5em" />
                  )
                }
              </button>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};
export default MessageEditor;