import Sidebar from "../Components/Sidebar";
import DashboardSidebar from "./DashboardSidebar";
import { useParams } from "react-router-dom";
import Documents from "../Components/Documents/Documents";
import { useAppDispatch, useAppSelector } from "../store";
import { useEffect } from "react";
import { getFolders } from "../store/DocumentSlice";
import PuffLoader from "react-spinners/PuffLoader";

function Folder() {
  const { folderId } = useParams();
  const dispatch = useAppDispatch();
  const { folderState, organizationState, userDetailsState } = useAppSelector((state) => ({
    folderState: state.document.folders,
    organizationState: state.organization.organization,
    userDetailsState: state.auth.userDetails
  }));

  useEffect(() => {
    if (folderState.length === 0 || folderState.folders.length === 0) {
      dispatch(getFolders());
    }
  }, []);

  const folder = folderState?.folders?.find((folder) => folder.folderId === folderId);

  return (
    <>
      {
        (userDetailsState && organizationState.length > 0) ?
          <div>
            <div className="flex md:flex-row flex-col">
              <DashboardSidebar />
              <Sidebar />
              <div
                className={`bg-backgroundColor  header-height flex justify-center items-center w-full md:px-0 px-2 my-pb-20`}
              >
                <div className="rounded-lg w-[100%] md:w-full lg:w-full">
                  <div className="relative">
                    <div className="header-height overflow-y-hidden lg:h-[100%] xl:h-[100vh] w-full">
                      {folder && <Documents folder={folder} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="spinner h-screen flex items-center justify-center">
            <PuffLoader
              color={userDetailsState?.primaryColor || organizationState[0]?.primaryColor}
              cssOverride={null}
              loading
              size={60}
            />
            <h1 className="text-3xl ms-3 font-bold">Loading...</h1>
          </div>
      }

    </>
  );
}

export default Folder;