import { likedChat, newQuery, updateChat } from "../store/finChat";
import { Icon } from "@iconify/react";
import { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../store/index";
import { toast } from "react-toastify";
import ReportModal from "../Components/ReportModal";
import clipboardCopy from "clipboard-copy";
import apiClient from "../utils/axiosInstance";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import useAuth from "../utils/useAuth";
import posthog from "posthog-js";
import { useNavigate } from "react-router-dom";

function ChatAnswer({
  answerChat,
  setIsChatLoading,
  setIsLoading,
  threadId,
  messageId,
  setQuestion,
}) {

  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const mackDown = useRef();
  const [copy, setCopy] = useState(false);
  const [isReportModal, setIsReportModal] = useState(false);
  const navigate = useNavigate()
  const { chatQueries, chats } = useAppSelector(
    (state) => ({
      chatQueries: state.finChat.chatQueries,
      chats: state.finChat.chatWithLimit,
    })
  );

  const handleRelated = async (data) => {
    setQuestion(data);
    try {
      setIsChatLoading(true);
      const body = {
        action: "chat",
        query: data,
        threadId: threadId,
        createdAt: new Date().toISOString(),
      };
      const response = await apiClient().post(`/chat`, body);
      if (
        response &&
        chatQueries?.chat?.queries?.length === 1 &&
        chats?.history?.length === 1 &&
        user?.email
      ) {
        posthog.identify(user?.email);
        posthog.capture("follow_up_chat_started", { email: user?.email });
      }
      dispatch(newQuery(response.data));
      dispatch(updateChat(response.data));
      setQuestion("");
    } catch (error) {
      toast.error(error);
    } finally {
      setQuestion("");
      setIsChatLoading(false);
    }
  };
  const handleLike = async (liked) => {
    if (liked === 1) {
      return;
    }
    try {
      setIsLoading(true);
      const body = {
        action: "like",
        messageId: messageId,
      };
      await apiClient().post(`/chat`, body);
      dispatch(likedChat(messageId));
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 1000);
    }
  }, [copy]);

  const handleCopyAnswer = () => {
    const answerText = answerChat?.answer?.answer
    if (answerText) {
      clipboardCopy(answerText)
        .then(() => {
          setCopy(answerChat);
        })
        .catch((error) => {
          // Handle any errors
        });
    }
  };

  return (
    <>
      <div className="px-4 py-2">
        <div className="flex justify-between mt-4 break-words mb-6">
          <div className="text-md xl:text-xl font-normal capitalize flex items-center justify-between w-full  text-textPrimary">
            <div className="heading-thread flex items-center">
              <div>
                <Icon icon="gridicons:menus" className="mr-2 w-6 h-6" />
              </div>
              <div>
                {answerChat?.query}
              </div>
            </div>
            <div className="close-btn md:hidden" >
              <button>
                <Icon
                  icon="carbon:close"
                  className="w-6 h-6 text-textPrimary"
                  onClick={() => navigate('/dashboard')}
                />
              </button>
            </div>
          </div>
        </div>

        {answerChat?.answer && (
          <div className="break-words border border-allBorderColor p-5 mb-5 rounded-lg bg-white">
            <p className="text-textPrimary flex items-center text-xl font-medium mb-2">
              Answer
            </p>
            <div className="relative mb-3">
              <div className="text-md text-textPrimary font-normal mb-3 relative">
                <div className="mb-4 markdown-content" ref={mackDown}>
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                  >
                    {answerChat?.answer.answer}
                  </Markdown>
                </div>
              </div>
            </div>
            <div className="flex items-center mt-4 justify-between">
              <div className="flex items-center mt-4">
                <button
                  onClick={handleCopyAnswer}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor ml-2 px-2 py-1 rounded-full"
                >
                  <Icon
                    icon={copy ? "uit:check" : "icon-park-outline:clipboard"}
                    className={`text-xl mr-1 ${copy ? " text-black" : "text-[#AEAEAE]"
                      }`}
                  />
                  Copy
                </button>
              </div>
              <div className="flex items-center mt-4">
                <button
                  onClick={() => {
                    handleLike(answerChat.liked);
                  }}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor ml-2 px-2 py-0.5 rounded-full"
                >
                  <Icon
                    icon="ei:like"
                    className={`text-3xl mr-1 ${answerChat.liked === 1 ? "text-black" : "text-[#AEAEAE]"
                      }`}
                  />
                  Like
                </button>
                <button
                  onClick={() => setIsReportModal(!isReportModal)}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor ml-2 px-2 py-1 rounded-full"
                >
                  <Icon
                    icon="solar:dislike-broken"
                    className={`text-2xl mr-1 ${answerChat.report === 1 ? "text-black" : "text-[#AEAEAE]"
                      }`}
                  />
                  Report
                </button>
              </div>
            </div>
          </div>
        )}

        {answerChat?.answer?.relevant_queries && (
          <div className="mt-8 border border-allBorderColor p-5 mb-5 rounded-lg bg-white">
            <p className="flex items-center text-md font-medium mb-4">
              Related
            </p>
            {answerChat?.answer?.relevant_queries.map((data, index) => {
              return (
                <div className="w-full" key={index}>
                  <button
                    onClick={() => handleRelated(data)}
                    className="text-textPrimary border-t-2 text-sm text-left w-full border-allBorderColor p-3 hover:hover:bg-allBorderColor"
                  >
                    {data}
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {answerChat?.report !== 1 && (
        <ReportModal
          isReportModal={isReportModal}
          setIsReportModal={setIsReportModal}
          messageId={messageId}
        />
      )}
    </>
  );
}

export default ChatAnswer;