import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";

export const getModelDetails = createAsyncThunk("getModelDetails", async () => {
    try {
        const response = await apiClient().get(`/model-details`);
        return response.data;
    } catch (error) {
        toast.error(`${error}`);
        throw error;
    }
});

export const aiModels = createSlice({
    name: "aiModels",
    initialState: {
        aiModels: {},
        status: null,
    },
    reducers: {
        changeModel: (state, action) => {
            const { providerId, models } = action.payload;
            state.aiModels.models[providerId] = models;
        },
        addNewModel: (state, action) => {
            const { providerId, models } = action.payload;
            state.aiModels.models[providerId] = models;
        },
        addNewProvider: (state, action) => {
            const { models, provider } = action.payload;
            state.aiModels.providers.push(action.payload.provider);

            if (action.payload?.models) {
                if (!state.aiModels.models[provider.modelId]) {
                    state.aiModels.models[provider.modelId] = [];
                }

                state.aiModels.models[provider.modelId].push(models[provider.modelId]);
            }

        },
        updateKey: (state, action) => {
            const { providerId, key } = action.payload;
            state.aiModels.providers.find((p) => p.modelId === providerId).provider_key = key;
        },
        changeProvider: (state, action) => {
            const { providerId } = action.payload;
            state.aiModels.providers = state.aiModels.providers.map((provider) => {
                if (provider.modelId === providerId) {
                    return {
                        ...provider,
                        currentUsed: true
                    }
                } else {
                    return {
                        ...provider,
                        currentUsed: false
                    }
                }
            })
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getModelDetails.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.aiModels = action.payload;
            })
            .addCase(getModelDetails.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(getModelDetails.rejected, (state, action) => {
                state.status = "failed";
            });
    },
});


export const aiModelReducer = aiModels.reducer;
export const { changeModel, addNewModel, addNewProvider, updateKey, changeProvider } = aiModels.actions;
