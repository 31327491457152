import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../store/index";
import { setAuthState } from "../store/authSlice";
import { toast } from "react-toastify";
import apiClient from "../utils/axiosInstance";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { usePostHog } from "posthog-js/react";
import { useGoogleLogin } from "@react-oauth/google";
import * as Yup from "yup";
import axios from "axios";
import AppleSignin from "react-apple-signin-auth";
import { jwtDecode } from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import { allowedEmailDomains } from "../utils/static";
import SpinnerLoading from "../Components/SpinnerLoading";
import quoromlogo from "../assets/logo-quorom.png";

const IndividualPlan = [
  {
    title: "Individual Plan",
    desc: [
      "With our individual plan, gain access to:",
      "Accurate Answers: Get precise information on US public companies, SEC flings, earnings transcripts, market data, and more.",
      "Comprehensive Research: Seamlessly search through web and private documents in a single interface.",
    ],
  },
  {
    title: "Business Plan",
    desc: [
      "Supercharge your team's productivity with business plan.Sign up with your business email and experience:",
      "AI-Enhanced Chat: Use Askfin as your work chat app with an AI financial analyst to answer a wide range of questions from any thread.",
      "Effortless Collaboration: Work seamlessly with colleagues and keep everyone on the same page.",
    ],
  },
];

const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .test("is-allowed-domain", "Email domain not allowed", function (value) {
      if (!value) return false;
      const domain = value.split("@")[1];
      return allowedEmailDomains.includes(domain);
    }),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

function Auth() {
  const posthog = usePostHog();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [authAction, setAuthAction] = useState(null);

  useEffect(() => {
    if (state?.toLogin) {
      setAuthAction("login");
    } else {
      setAuthAction("signup");
    }
  }, [state?.toLogin]);

  const location = useLocation();
  const { action } = location.state || {};

  useEffect(() => {
    if (action) {
      setAuthAction(action);
    }
  }, [action]);

  const redirectSignUpButton = () => {
    setAuthAction('signup');
  };

  const redirectLoginButton = () => {
    setAuthAction('login');
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailAuth = async (values) => {
    const { email, password } = values;
    try {
      setLoading(true);
      const response = await apiClient().post(
        `${process.env.REACT_APP_API_URL}/${authAction}`,
        {
          email: email,
          password: password,
        }
      );
      if (response && response.data && authAction === "login") {
        localStorage.setItem("authToken", response.data.token);
        navigate("/dashboard");
        dispatch(setAuthState(true));
      }
      if (response && response.data && authAction === "signup") {
        posthog.capture("signup", { email: email });
        posthog.alias(email);
        navigate("/email/verification", { state: { email: email } });
      }
    } catch (error) {
      toast.error(error);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const signInWithGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const googleApiResponse = await axios.get(
          `${process.env.REACT_APP_GOOGLE_API}?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        );

        const result = await axios.post(
          `${process.env.REACT_APP_API_URL}/sign-in-with-services`,
          {
            service: "google",
            name: googleApiResponse.data.name,
            email: googleApiResponse.data.email,
          }
        );

        if (result && result.data) {
          dispatch(setAuthState(true));
          if (result.data.isFirstTime) {
            navigate(`/create-profile/${result.data.token}`);
          } else {
            localStorage.setItem("authToken", result.data.token);
            navigate("/dashboard");
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      console.log("Login Failed:", error);
    },
  });

  const successResponse = async (response) => {
    setLoading(true);
    let data = {};
    try {
      const decodedData = jwtDecode(response.authorization.id_token);

      data = {
        name: response.user ? response.user.name : "",
        email: response.user ? response.user.email : decodedData.email,
      };

      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/sign-in-with-services`,
        {
          service: "apple",
          name: data.name || "",
          email: data.email,
        }
      );

      if (result && result.data) {
        dispatch(setAuthState(true));
        if (result.data.isFirstTime) {
          navigate(`/create-profile/${result.data.token}`);
        } else {
          localStorage.setItem("authToken", result.data.token);
          navigate("/dashboard");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      {loading && <SpinnerLoading isLoading={loading} />}
      <div>
        <nav className="bg-white border-gray-200">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a href="/" className="flex items-center space-x-3">
              <img src={quoromlogo} className="md:h-8 h-12" alt="quorom Logo" />
            </a>
            <div
              className="items-center justify-between hidden w-full md:flex md:w-auto"
              id="navbar-cta"
            >
              <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white ">
                <li>
                  <a
                    href="#"
                    className="block py-2 px-3 md:p-0 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700"
                    aria-current="page"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700"
                  >
                    Features
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 "
                  >
                    Pricing
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex space-x-3 md:space-x-0">
              <div className="hidden md:block">
                <button
                  type="button"
                  className="bg-transparent focus:ring-none focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center"
                  onClick={redirectLoginButton}
                >
                  Sign In
                </button>
                <button
                  type="button"
                  onClick={redirectSignUpButton}
                  className="border-2 border-gray-300 text-[#8934E9] focus:ring-unset focus:outline-none font-medium rounded-[50px] text-sm px-4 py-2 text-center "
                >
                  Sign Up
                </button>
              </div>
              <button
                data-collapse-toggle="navbar-cta"
                type="button"
                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="navbar-cta"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>
            </div>
          </div>
        </nav>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 border-t border-lendingButton">
          <div
            className="md:col-span-5 bg-lendingButton px-8 text-white"
            style={{ height: "calc(100vh - 81px)" }}
          >
            {IndividualPlan.map((plan, index) => (
              <div key={index}>
                <p
                  key={index}
                  className="text-2xl font-bold pt-16 tracking-wide"
                >
                  {plan.title}
                </p>

                <ul className="list-disc">
                  {plan.desc.map((item, idx) => (
                    <li
                      key={idx}
                      className="text-lg font-normal mt-5 text-white"
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="md:col-span-7 px-4 md:px-0 flex items-center">
            {authAction === 'signup' ? (
              <>
                <div className="max-w-md mx-auto p-6 bg-white rounded-lg dark:bg-gray-800">
                  <h2 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">
                    Create your free account
                  </h2>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={SignUpSchema}
                    onSubmit={handleEmailAuth}
                  >
                    {({ errors, touched, isSubmitting }) => (
                      <Form>
                        <div className="mb-6">
                          <label
                            htmlFor="email"
                            className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300"
                          >
                            Work Email
                          </label>
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            className="w-full px-3 py-2 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-lendingButton dark:bg-gray-700 dark:text-white"
                            placeholder="you@example.com"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-red-500 mt-1 text-sm"
                          />
                        </div>
                        <div className="mb-6">
                          <div className="relative">
                            <label
                              htmlFor="email"
                              className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300"
                            >
                              Set a password
                            </label>
                            <Field
                              type={showPassword ? "text" : "password"}
                              id="password"
                              name="password"
                              className="w-full px-3 py-2 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-lendingButton"
                              placeholder="Password"
                            />
                            <span
                              className="absolute right-0 bottom-2 flex items-center pr-4 cursor-pointer"
                              onClick={toggleShowPassword}
                            >
                              <Icon
                                icon={showPassword ? "mdi:eye-off" : "mdi:eye"}
                                className="text-gray-400 w-6 h-6"
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-red-500 mt-1 text-sm"
                          />
                        </div>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="w-full px-4 py-2 text-white font-medium bg-lendingButton rounded-lg hover:bg-lendingButton focus:outline-none focus:ring-2 focus:ring-lendingButton focus:ring-offset-2 transition-colors duration-300 ease-in-out"
                        >
                          Continue
                        </button>
                      </Form>
                    )}
                  </Formik>

                  <>
                    <hr className="my-6 border-gray-300 dark:border-gray-600" />
                    <button
                      onClick={() => signInWithGoogle()}
                      className="flex items-center m-auto justify-center mt-5 p-2 w-full md:w-96 text-gray-900 text-center rounded-full font-normal text-lg bg-allBorderColor hover:bg-lendingButton hover:text-white"
                    >
                      <Icon icon="prime:google" className="text-xl" />
                      Continue with Google
                    </button>
                    <AppleSignin
                      authOptions={{
                        clientId: `${process.env.REACT_APP_APPLE_CLIENT_ID}`,
                        scope: "name email",
                        redirectURI: `${process.env.REACT_APP_APPLE_REDIRECT_URI}`,
                        state: "state",
                        nonce: "nonce",
                        usePopup: true,
                      }}
                      onSuccess={(response) => successResponse(response)}
                      onError={(error) => console.error(error)}
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          className="flex items-center m-auto justify-center mt-5 p-2 w-full md:w-96 text-gray-900 text-center rounded-full font-normal text-lg bg-allBorderColor hover:bg-lendingButton hover:text-white"
                        >
                          <Icon icon="prime:apple" className="text-xl" />
                          Continue with Apple
                        </button>
                      )}
                    />
                  </>
                </div>
              </>
            ) : (
              <div className="w-[28rem] mx-auto p-6 bg-white rounded-lg dark:bg-gray-800 mt-8">
                <h2 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">
                  Sign In
                </h2>
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Invalid email")
                      .required("Email is required"),
                    password: Yup.string().required("Password is required"),
                  })}
                  onSubmit={handleEmailAuth}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="mb-6">
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300"
                        >
                          Email address
                        </label>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          className="w-full px-3 py-2 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-lendingButton dark:bg-gray-700 dark:text-white"
                          placeholder="you@example.com"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 mt-1 text-sm"
                        />
                      </div>
                      <div className="mb-6">
                        <div className="relative">
                          <Field
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className="w-full px-3 py-2 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-lendingButton dark:bg-gray-700 dark:text-white"
                            placeholder="Password"
                          />
                          <span
                            className="absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer"
                            onClick={toggleShowPassword}
                          >
                            <Icon
                              icon={showPassword ? "mdi:eye-off" : "mdi:eye"}
                              className="text-gray-400 w-6 h-6"
                            />
                          </span>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500 mt-1 text-sm"
                        />
                      </div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full px-4 py-2 text-white font-medium bg-lendingButton rounded-lg hover:bg-lendingButton focus:outline-none focus:ring-2 focus:ring-lendingButton focus:ring-offset-2 transition-colors duration-300 ease-in-out"
                      >
                        Sign In
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Auth;
