import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/index";
import apiClient from "../utils/axiosInstance";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../utils/useAuth";
import TeamModal from "./Team/TeamModal";
import { getTeams, updateTeamNotification } from "../store/teamSlice";
import WebChatModel from "./WebChatModel";
import { deleteUserLimitedChat, getUserChatsWithinLimit, resetMainThreadNotification, starredThread, updateChatNotification } from "../store/finChat";
import { setActiveTab, setExpandedTab } from "../store/sidebarSlice";
import { getOrganization, resetMemberNotification } from "../store/organization";
import { format } from "date-fns";
import Modal from "./Modal";
import SidebarRenderThreads from "./sidebar/SidebarRenderThreads";
import SidebarRenderDMs from "./sidebar/SidebarRenderDMs";
import SidebarRenderFolders from "./sidebar/SidebarRenderFolders";
import SidebarRenderSpace from "./sidebar/SidebarRenderSpace";
import SidebarRenderStarred from "./sidebar/SidebarRenderStarred";
import { getUserDetails } from "../store/authSlice";

function Sidebar({ SetDashboard, dashboard }) {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { chatWithLimitState, teamState, organizationState, expandedTab, activeTab, dashboardActiveTabState, userDetailsState } = useAppSelector((state) => ({
    userDetailsState: state.auth.userDetails,
    chatWithLimitState: state.finChat.chatWithLimit,
    teamState: state.teams.teams,
    organizationState: state.organization.organization,
    expandedTab: state.sidebar.expandedTab,
    activeTab: state.sidebar.activeTab,
    dashboardActiveTabState: state.sidebar.dashboardActiveTab,
  }));
  const [teamModal, setTeamModal] = useState(false);
  const [initialTeam, setInitialTeam] = useState(null);
  const [loadingTab, setLoadingTab] = useState(null);
  const [activeItemId, setActiveItemId] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [discardedThreadId, setDiscardedThreadId] = useState(null);

  useEffect(() => {
    if (user && !userDetailsState) {
      dispatch(getUserDetails(user?.userId));
    }
  }, [user]);

  useEffect(() => {
    if (activeItemId !== null) {
      setCloseSideBar(true);
    }
  }, [activeItemId])

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const lastPart = pathParts[pathParts.length - 1];
    if (
      lastPart !== "dashboard" &&
      lastPart !== "chat-history" &&
      lastPart !== "teams" &&
      lastPart !== "dms"
    ) {
      setActiveItemId(lastPart);
    } else {
      setActiveItemId(null);
    }
  }, [location]);

  useEffect(() => {
    if (user?.userId && !chatWithLimitState?.history?.length > 0) {
      dispatch(getUserChatsWithinLimit(15));
    }
  }, [user]);

  const handleItemClick = (itemId) => {
    setActiveItemId(itemId);
  };

  const discardedThread = async () => {
    try {
      await apiClient().put(`/thread-starred-discarded/${discardedThreadId}`, {
        field: "discarded",
      })
      dispatch(deleteUserLimitedChat(discardedThreadId));
    } catch (error) {
      console.error("Error discarding thread:", error);
    }
  }

  const openDashboard = () => {
    navigate("/dashboard");
    if (typeof SetDashboard === 'function') {
      SetDashboard(true);
    } else {
      console.error('SetDashboard is not a function');
    }
  };

  const handleDiscarded = (threadId) => {
    setShowModal(true);
    setDiscardedThreadId(threadId);
  }

  const confirmDiscardedThread = () => {
    discardedThread();
    setShowModal(false);
    setShowButtons(false);
  };

  const isItemActive = (itemId) => {
    return activeItemId === itemId || location.pathname.includes(itemId);
  };

  const openModal = (tabType) => {
    if (tabType === "spaces") {
      setTeamModal(true);
    }
  };

  const handleTabClick = async (tab) => {
    if (expandedTab !== tab) {
      dispatch(setExpandedTab(tab));
      dispatch(setActiveTab(tab));
      setLoadingTab(tab);

      switch (tab) {
        case "threads":
          if (chatWithLimitState?.length === 0) {
            dispatch(getUserChatsWithinLimit(15)).finally(() =>
              setLoadingTab(null)
            );
          } else {
            setLoadingTab(null);
            if (chatWithLimitState?.notification > 0) {
              await apiClient().put(`/reset-notification/${user?.userId}`);
              dispatch(resetMainThreadNotification());
            }
          }
          break;
        case "dms":
          if (organizationState?.length === 0) {
            dispatch(getOrganization()).finally(() => setLoadingTab(null));
          } else {
            setLoadingTab(null);
          }
          break;
        case "spaces":
          if (!teamState?.length > 0) {
            dispatch(getTeams()).finally(() => setLoadingTab(null));
          } else {
            setLoadingTab(null);
          }
          break;
      }
    } else {
      dispatch(setExpandedTab(null));
      dispatch(setActiveTab(null));
      setLoadingTab(null);
    }
  };

  const resetNotification = async (teamId) => {
    try {
      await apiClient().put(`/reset-notification/${teamId}`);
      dispatch(updateTeamNotification({ teamId: teamId, notification: 0 }));
    } catch (error) {
      console.log("error in reset notification:", error);
    }
  };

  const resetThreadChatsNotification = async (threadId) => {
    try {
      await apiClient().put(`/reset-notification/${threadId}`);
      dispatch(updateChatNotification({ threadId: threadId, notification: 0 }));
    } catch (error) {
      console.error("Error resetting notification:", error);
    }
  };

  const resetOrgMemberNotification = async (memberId) => {
    try {
      await apiClient().put(`/reset-notification/${memberId}`);
      dispatch(
        resetMemberNotification({ memberId: memberId, notification: 0 })
      );
    } catch (error) {
      console.error("Error resetting notification:", error);
    }
  };

  const handleStarredThread = async (threadId) => {
    try {
      dispatch(starredThread(threadId));
      await apiClient().put(`/thread-starred-discarded/${threadId}`, {
        field: "starred",
      });
    } catch (error) {
      console.error("Error starring thread:", error);
    }
  };

  const TabContent = ({ items, linkPrefix1, tabType, openModal, isLoading, closeSideBar, setCloseSideBar }) =>
    isLoading || activeTab === tabType ? (
      isLoading ? (
        <div className="space-y-2 px-2 mt-2">
          {[1, 2, 3, 4].map((_, index) => (
            <div key={index} className="animate-pulse flex items-center">
              <div className="h-4 bg-gray-200 rounded w-full"></div>
            </div>
          ))}
        </div>
      ) : items?.length === 0 ? (
        <div className="flex justify-evenly">
          <div className="p-2 text-sm text-center text-gray-500">
            No items found
          </div>
          <div>
            {tabType === "spaces" && (
              <button
                onClick={() => openModal(tabType)}
                className="ml-2 rounded-full w-6 h-6 flex items-center justify-center hover:bg-gray-200"
              >
                <Icon icon="mdi:plus" width="1.2em" height="1.2em" />
              </button>
            )}
          </div>
        </div>
      ) : tabType === "threads" ? (
        <ul className="space-y-2 max-h-60 overflow-y-auto">
          {items?.slice(0, 5).map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  setCloseSideBar(true);
                }}
                className={`text-sm flex  items-center p-2 rounded-lg transition-colors duration-200`}
                style={{
                  background: isItemActive(item.id) ? organizationState[0]?.secondaryColor : "transparent",
                }}
              >
                <div
                  className="relative group"
                  onMouseEnter={() => setShowButtons(index)}
                  onMouseLeave={() => setShowButtons(false)}
                >
                  <div className="flex items-center">
                    <Icon
                      icon="heroicons:chat-bubble-left-ellipsis"
                      width="1.5em"
                      height="1.5em"
                      className="text-pricingText mr-2"
                    />
                    <Link to={item.type === "chat" ? `${linkPrefix1}/${item.id}` : `/thread/${item?.id}`} className={`truncate ${item.notification > 0 ? 'w-48' : 'w-56'} flex-grow text-landingText capitalize  font-normal ${item.notification > 0 ? "text-gray-900 font-semibold" : ""}`}
                      onClick={(e) => {
                        handleItemClick(item.id);
                        if (item.notification > 0) {
                          e.preventDefault();
                          resetThreadChatsNotification(item.id).then(() => {
                            navigate(item.type === "chat" ? `${linkPrefix1}/${item.id}` : `/thread/${item?.id}`);
                          });
                        }
                      }} title={item.name} >
                      {item.name}
                    </Link>
                  </div>
                  {showButtons === index && (
                    <div
                      className={`absolute right-0 top-[-4px] flex space-x-2 rounded-md px-2 py-1 transition-opacity bg-white duration-200 ${showButtons
                        ? "opacity-100"
                        : "opacity-100 group-hover:opacity-100"
                        }`}
                    >
                      <p className="text-sm font-normal">{format(new Date(item.createdAt), "d MMM")}</p>
                      <button className="rounded" style={{ color: organizationState[0]?.primaryColor }} onClick={() => { handleStarredThread(item.id) }}>
                        {
                          item.starred ? (
                            <Icon
                              icon="mdi:star"
                              width="1.5em"
                              height="1.5em"
                            />
                          ) : (
                            <Icon
                              icon="mdi:star-outline"
                              width="1.5em"
                              height="1.5em"
                            />
                          )
                        }
                      </button>
                      <button
                        className="rounded"
                        onClick={() => handleDiscarded(item.id)}
                        style={{ color: organizationState[0]?.primaryColor }}
                      >
                        <Icon
                          icon="gravity-ui:bell-slash"
                          width="1.2em"
                          height="1.2em"
                        />
                      </button>
                    </div>
                  )}
                </div>

                {item.notification > 0 && (
                  <span className="bg-red-500 text-white text-xs font-bold w-5 h-5 p-[1.5px] rounded-full ml-2 text-center">
                    {item.notification}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      ) : tabType === "dms" ? (
        <ul className="space-y-2 mt-2 max-h-60 overflow-y-auto">
          {items?.map((item, index) => {
            return (
              <li
                key={index}
                className={`text-sm flex  items-center p-2 rounded-lg transition-colors duration-200`}
                style={{
                  background: isItemActive(item.id) ? organizationState[0]?.secondaryColor : "transparent",
                }}

              >
                <div className="relative mr-2">
                  <Icon
                    icon="heroicons:user-circle"
                    width="1.8em"
                    height="1.8em"
                    className="text-pricingText"
                  />
                  <span
                    className={`absolute bottom-0 right-0 w-2 h-2 rounded-full ${item.userStatus === "online"
                      ? "bg-green-500"
                      : "bg-gray-500"
                      }`}
                  ></span>
                </div>
                <Link
                  to={`${linkPrefix1}/${item.id}`}
                  onClick={(e) => {
                    handleItemClick(item.id);
                    if (item.notification > 0) {
                      e.preventDefault();
                      resetOrgMemberNotification(item.id).then(() => {
                        navigate(`${linkPrefix1}/${item.id}`);
                      });
                    }
                  }}
                  className={`truncate w-52 mt-1 flex-grow text-landingText capitalize  font-normal  ${item.notification > 0 && "text-gray-900 font-semibold"
                    }`}
                >
                  <span>{item.name}</span>
                </Link>
                {item.notification > 0 && (
                  <span className="bg-red-500 text-white text-xs font-bold w-5 h-5 p-[1.5px] rounded-full ml-2 text-center">
                    {item.notification}
                  </span>
                )}
              </li>
            )
          })}
        </ul>
      ) : (
        <ul className="space-y-2  mt-2 max-h-60 overflow-y-auto">
          {items.map((item, index) => {
            return (
              <li
                key={index}
                className={`text-sm flex justify-between items-center p-1 rounded-lg  transition-colors duration-200`}
                style={{
                  background: isItemActive(item.id) ? organizationState[0]?.secondaryColor : "transparent",
                }}
              >
                <Icon icon={item.teamType === "public" ? "solar:hashtag-linear" : "mdi:lock"} width="1.5em" height="1.5em" className="text-pricingText mr-2" />
                <Link
                  onClick={() => {
                    resetNotification(item.id);
                    handleItemClick(item.id);
                  }}
                  to={`${linkPrefix1}/${item.id}`}
                  className={`truncate w-52 mt-1 flex-grow text-landingText capitalize  font-normal  ${item.notification > 0 && "text-gray-900 font-semibold"
                    }`}
                >
                  {item.name}
                </Link>
                {item.notification > 0 && (
                  <p className="bg-red-500 text-white text-xs font-bold w-5 h-5 p-[0.5px] rounded-full ml-2 text-center">
                    {item.notification}
                  </p>
                )}
              </li>
            );
          })}
        </ul>
      )
    ) : null;

  const SidebarTab = ({ text, tab, children, handleTabClick, notificationCount, isLocked }) => (
    <li>
      <button
        className={`inline-flex items-center py-2 p-1 w-full ${isLocked ? 'cursor-not-allowed opacity-50' : ''}`}
        onClick={() => !isLocked && handleTabClick(tab)}
      >
        <>
          <p className="font-[600] text-black">{text}</p>
          {notificationCount > 0 && (
            <span className="bg-red-500 text-white text-sm font-bold w-5 h-5 p-[0.5px] rounded-full ml-4">
              {notificationCount}
            </span>
          )}
          <Icon
            icon={
              isLocked
                ? "heroicons:lock-closed"
                : expandedTab === tab
                  ? "ep:arrow-up-bold"
                  : "ep:arrow-down-bold"
            }
            className="ml-auto text-secondary"
          />
        </>
      </button>
      {!isLocked && expandedTab === tab && children}
    </li>
  );

  const recentChats = chatWithLimitState?.history?.map((chat) => {
    const data = {
      id: chat.threadId,
      name: chat.chatName,
      type: chat.type,
      createdAt: chat.createdAt,
      starred: chat.starred,
    };
    if (chat.type === "thread") {
      data.notification = chat.notification;
      data.tag = chat.tag;
    }
    return data;
  });

  const recentDms = organizationState[0]?.members?.filter((member) => member.isAccepted)?.filter((member) => member.userId !== user?.userId)?.map((activeMember) => {
    return {
      id: activeMember.userId,
      name: activeMember.name,
      notification: activeMember.notification,
      userStatus: activeMember.user_status,
    };
  });

  const teams = teamState?.map((team) => {
    return {
      id: team.teamId,
      name: team.teamName,
      notification: team.notification,
      teamType: team.teamType,
    };
  });

  const [closeSideBar, setCloseSideBar] = useState(false);
  const renderHome = () => (
    <ul className="space-y-2 px-3 py-1" style={{ height: "calc(100vh - 90px)" }}>
      <li>
        <div className="flex items-center justify-between py-5 border-b-2">
          <p className="text-xl font-bold ">Home</p>
          <button onClick={() => openDashboard()}>
            <Icon
              icon="heroicons:pencil-square"
              width="1.3em"
              height="1.3em"
              className="text-secondary"
            />
          </button>
        </div>
      </li>

      <SidebarTab
        icon="ph:chats-circle-bold"
        text="Threads"
        tab="threads"
        isExpanded={expandedTab === "threads"}
        handleTabClick={handleTabClick}
        notificationCount={chatWithLimitState?.notification}
      >
        <TabContent
          items={recentChats}
          linkPrefix1="/answer-search"
          linkPrefix2="/chat-history"
          tabType="threads"
          openModal={openModal}
          setCloseSideBar={setCloseSideBar}
          closeSideBar={closeSideBar}
          isLoading={loadingTab === "threads"}
        />
      </SidebarTab>
      <SidebarTab
        icon="hugeicons:add-team"
        text="Spaces"
        tab="spaces"
        isExpanded={expandedTab === "spaces"}
        handleTabClick={handleTabClick}
      >
        <TabContent
          items={teams}
          linkPrefix1="/team"
          linkPrefix2="/teams"
          tabType="spaces"
          openModal={openModal}
          isLoading={loadingTab === "spaces"}
        />
      </SidebarTab>

      <SidebarTab
        icon="hugeicons:add-team"
        text="Direct Messages"
        tab="dms"
        isExpanded={expandedTab === "dms"}
        handleTabClick={handleTabClick}
      >
        <TabContent
          items={recentDms}
          linkPrefix1="/dm"
          linkPrefix2="/dms"
          tabType="dms"
          openModal={openModal}
          isLoading={loadingTab === "dms"}
        />
      </SidebarTab>
    </ul>
  );

  const renderContent = () => {
    switch (dashboardActiveTabState) {
      case "Threads":
        return (
          <SidebarRenderThreads
            isItemActive={isItemActive}
            setActiveItemId={setActiveItemId}
          />
        );
      case "DMs":
        return (
          <SidebarRenderDMs
            isItemActive={isItemActive}
            setActiveItemId={setActiveItemId}
          />
        );
      case "Files":
        return (
          <SidebarRenderFolders
            setActiveItemId={setActiveItemId}
            isItemActive={isItemActive}
          />
        );
      case "Home":
        return renderHome();
      case "Space":
        return (
          <SidebarRenderSpace
            setActiveItemId={setActiveItemId}
            isItemActive={isItemActive}
          />
        );
      case "Starred":
        return (
          <SidebarRenderStarred
            setActiveItemId={setActiveItemId}
            isItemActive={isItemActive}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={`sidebar ${closeSideBar ? "hidden" : "flex"} ${!dashboard ? "flex" : "hidden"} md:flex`}>
        <div className={`md:flex flex-col header-height xl:w-72 lg:w-60 md:w-48 w-full bg-sidebarBgColor justify-between transition-width duration-300 z-10`} >
          <div className="">{renderContent()}</div>
        </div>
      </div>
      <TeamModal
        teamModal={teamModal}
        setTeamModal={setTeamModal}
        initialTeam={initialTeam}
        setInitialTeam={setInitialTeam}
      />
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        confirmAction={confirmDiscardedThread}
        label={"Discard Thread"}
        description={"Are you sure you want to discard this thread? This action cannot be undone and the thread will be permanently removed from your account."}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
      />
    </>
  );
}

export default Sidebar;