import React, { memo, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import Avatar from "react-avatar";
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import { format } from "date-fns";
import useAuth from "../utils/useAuth";
import { Document, Page, pdfjs } from "react-pdf";
import Logo from "../assets/logo.png";
import MessageLinkPreview from "./MessageLinkPreview";
import { useAppDispatch } from "../store";
import { getMessageReplies } from "../store/ThreadSlice";
import moment from "moment/moment";

const MessageItem = memo(({ msg, handleRelatedQueryClick, organization, isDisplayReplies, tab, setIsReplying, isReplying }) => {
  console.log("🚀 ~ MessageItem ~ msg:", msg)
  const { user } = useAuth();
  const [isHovered, setIsHovered] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const dispatch = useAppDispatch();

  const downloadFile = (url, fileName) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
        return "ph:file-png-bold";
      case "pdf":
        return "ph:file-pdf-bold";
      case "doc":
      case "docx":
        return "ph:file-doc-bold";
      default:
        return "mdi:file";
    }
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const renderFile = (file) => (
    <div
      key={file.fileName}
      className="flex flex-col justify-between p-2 rounded-lg border-2 bg-white mr-5 mb-2 w-[19rem]"
    >
      <div className="flex items-center justify-between w-full border-b-2 pb-2">
        <div className="flex items-center">
          <Icon icon={getFileIcon(file.fileName)} className="w-6 h-6 mr-2" />
          <p className="text-sm text-gray-700 flex-grow w-40 truncate">
            {file.fileName}
          </p>
        </div>
        <div className="flex-shrink-0">
          {isHovered && (
            <div className="flex items-center">
              <button
                onClick={() => downloadFile(file.url, file.fileName)}
                className="w-6 h-6 flex items-center justify-center bg-sidebarBgColor rounded-full  hover:bg-gray-300  mr-2"
                style={{ color: organization?.primaryColor }}
              >
                <Icon icon="octicon:download-24" className="w-4 h-4" />
              </button>
              <button className="w-6 h-6 flex items-center justify-center bg-sidebarBgColor rounded-full text-red-500 hover:bg-gray-300">
                <Icon icon="mdi:close" className="w-4 h-4" />
              </button>
            </div>
          )}
        </div>
      </div>
      {renderFilePreview(file)}
    </div>
  );

  const renderFilePreview = (file) => {
    const extension = file.fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "txt":
        return (
          <pre className="w-full mt-2 max-h-40 overflow-auto">
            File content here
          </pre>
        );
      case "jpg":
      case "jpeg":
      case "png":
        return (
          <div className="mt-2 w-full h-40 overflow-hidden">
            <img
              src={file.url}
              alt={file.fileName}
              className="w-full h-full object-contain"
            />
          </div>
        );
      case "pdf":
        return (
          <div className="pdf-preview mt-2 w-full h-40 overflow-hidden">
            <Document file={file.url}>
              <Page pageNumber={1} width={240} />
            </Document>
          </div>
        );
      default:
        return null;
    }
  };

  const renderAvatar = () => {
    if (msg?.isAiChat) {
      return (
        <img
          src={Logo}
          alt="quorom Logo"
          className="w-10 h-10 mr-2 text-gray-950 rounded-[50%] p-2"
          style={{ backgroundColor: "rgb(245, 245, 247)" }}
        />
      );
    } else {
      return (
        <Avatar
          name={msg?.senderName}
          size="40"
          round={true}
          className="mr-2"
          textSizeRatio={2}
          fgColor="#1D1D1F"
        />
      );
    }
  };

  const getSenderName = () => {
    return msg?.isAiChat ? organization?.chatBotName : msg?.senderName;
  };

  const handleRelatedClick = async (question) => {
    handleRelatedQueryClick(question);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const renderMessage = () => {
    if (msg?.isAiChat) {
      return (
        <>
          {msg.answer?.answer && (
            <div className="ml-12 mt-[-15px]">
              <div className="markdown-content">
                <Markdown remarkPlugins={[remarkGfm]}>{msg.answer.answer}</Markdown>
              </div>
            </div>
          )}
          {msg.answer?.relevant_queries && msg.answer.relevant_queries.length > 0 && (
            <div className="ml-12 mt-4 text-[#676767]">
              <h3 className="font-bold mb-2">Related:</h3>
              <ol className="list-decimal list-inside">
                {msg.answer.relevant_queries.map((question, index) => (
                  <li key={index} className="mb-1">
                    <button
                      onClick={() => handleRelatedClick(question)}
                      className="hover:underline"
                    >
                      {question}
                    </button>
                  </li>
                ))}
              </ol>
            </div>
          )}
        </>
      );
    } else {
      switch (msg.messageType) {
        case "text":
          const urlRegex = /(https?:\/\/[^\s]+)/g;
          const match = msg.message.match(urlRegex);
          if (match) {
            const url = match[0];
            const parts = msg.message.split(urlRegex);
            return (
              <>
                <p className="ml-12 mt-[-15px]">
                  {parts.map((part, index) =>
                    index % 2 === 0 ? part : (
                      <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                        {part}
                      </a>
                    )
                  )}
                </p>
                {showPreview && <MessageLinkPreview url={url} onClose={handleClosePreview} />}
              </>
            );
          }
          return (
            <div className="ml-12 mt-[-15px] markdown-content">
              <Markdown remarkPlugins={[remarkGfm]}>{msg.message}</Markdown>
            </div>
          );
        case "file":
        case "text-file":
          return (
            <>
              {msg.messageType === "text-file" && (
                <div className="ml-12 mt-[-15px] markdown-content">
                  <Markdown remarkPlugins={[remarkGfm]}>{msg.message}</Markdown>
                </div>
              )}
              <div className="flex flex-wrap ml-12 mt-2">
                {msg.files.map((file) => renderFile(file))}
              </div>
            </>
          );
        default:
          return null;
      }
    }
  };

  const replyInThread = (messageId) => {
    const data = {
      messageId,
      tab,
    };
    dispatch(getMessageReplies(data));
    setIsReplying(true);
  };


  return (
    <>
      {(!msg?.isAiChat || (msg?.isAiChat && msg?.answer?.answer)) && (
        <div
          className={`my-2 ${!isReplying && "xl:px-20"
            } md:p-2 rounded transition-all duration-300 ease-in-out w-full`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="flex items-center justify-between relative">
            <div className="w-full">
              <div>
                <div className="flex items-start">
                  {renderAvatar()}
                  <p className="text-lg font-semibold capitalize  text-[16px]  leading-[20.75px] ">
                    {getSenderName()}
                    <span className="text-gray-400 ml-2 text-[14px] leading-[18.16px]">
                      {format(new Date(msg?.createdAt), "h:mm")}
                    </span>
                  </p>
                </div>
                <div>{renderMessage()}</div>
                {msg?.hasReplies && isDisplayReplies && (
                  <div
                    className={`flex items-center justify-between ml-12 md:w-[50%] w-[calc(100% - 3rem)] p-1 pl-3 rounded-md cursor-pointer transition-colors duration-200 ${isHovered ? "bg-gray-100" : ""
                      }`}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => {
                      replyInThread(
                        tab === "dm" ? msg?.directMessageId : msg?.messageId
                      );
                    }}
                  >
                    <div className="flex item-center">
                      <div className="flex">
                        {msg?.profilePicturesUsers?.map((pics, index) => {
                          if (
                            pics.is_profile_picture === true
                          ) {
                            return (
                              <img
                                src={pics.profilepicture}
                                key={index}
                                className="-ml-2 first:ml-0  h-6 w-6"
                              />
                            );
                          } else {
                            return <Avatar
                              name={pics?.name.split("")[0]}
                              size="25"
                              round={true}
                              className="-ml-2 first:ml-0 border-1"
                              textSizeRatio={2}
                              fgColor="#1D1D1F"
                            />
                          }

                        })}
                      </div>
                      <p className="ms-2 text-blue-500 font-bold">
                        {msg?.replyCounter}{" "}
                        {msg?.replyCounter.length === 1 ? "reply" : "replies"}
                      </p>
                      <p className="ml-2">
                        {isHovered
                          ? "View thread"
                          : `Last reply ${moment(
                            msg?.lastRepliedAt
                          ).fromNow()}`}
                      </p>
                    </div>
                    <div className={`${isHovered ? "block" : "hidden"}`}>
                      <p className="items-end">
                        <Icon
                          icon="ic:twotone-greater-than"
                          width="1.2rem"
                          height="1.2rem"
                        />
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="absolute top-[-8%] right-[0%]">
                { isHovered && (
                    <div className="transition-all duration-300 ease-in-out bg-gray-200 py-2 px-4 rounded">
                      <div className="flex items-center">
                        <button className="bg-borderColor p-1.5 rounded-full mr-2 text-lendingButton hover:bg-gray-200 transition-colors duration-200"
                          style={{ color: organization?.primaryColor }}
                        >
                          <Icon
                            icon="heroicons:clipboard-document-check"
                            className="w-4 h-4"
                          />
                        </button>
                        {isDisplayReplies && (
                          <button
                            className="bg-borderColor p-1.5 rounded-full mr-2 text-textPrimary hover:bg-gray-200 transition-colors duration-200"
                            onClick={() => {
                              replyInThread(
                                tab === "dm"
                                  ? msg?.directMessageId
                                  : msg?.messageId
                              );
                            }}
                          >
                            <Icon icon="bi:chat-text" className="w-4 h-4" />
                          </button>
                        )}
                        <button className="bg-borderColor p-1.5 rounded-full mr-2 text-textPrimary hover:bg-gray-200 transition-colors duration-200">
                          <Icon
                            icon="fluent:emoji-add-24-regular"
                            className="w-4 h-4"
                          />
                        </button>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export const MessageList = memo(({ messages, handleRelatedQueryClick, organization, isReplying, setIsReplying, tab, isDisplayReplies = true, }) => (
  <>
    {messages?.map((msg, index) => (
      <MessageItem key={msg.messageId || index} msg={msg} handleRelatedQueryClick={handleRelatedQueryClick} organization={organization} isReplying={isReplying}
        setIsReplying={setIsReplying} isDisplayReplies={isDisplayReplies} tab={tab} />
    ))}
  </>
));

export const EmptyState = () => (
  <div className="flex flex-col items-center justify-center h-full text-gray-500">
    <Icon icon="carbon:chat" width="64" height="64" />
    <h2 className="mt-4 text-xl font-semibold">No messages yet</h2>
    <p className="mt-2">Be the first to start a conversation!</p>
  </div>
);

export const HeaderSkeleton = () => (
  <div className="bg-white shadow-sm xl:px-20 lg:px-4 md:px-2 lg:py-4 md:py-2 flex items-center">
    <div className="w-48 h-6 bg-gray-200 rounded animate-pulse"></div>
  </div>
);
export const MessageSkeleton = ({ isReplying = false }) => (
  <div
    className={`xl:m-2 ${isReplying ? "px-1" : "xl:px-20 lg:px-10"
      }  px-4 py-4 rounded`}
  >
    <div className="flex items-start">
      <div className="w-10 h-10 bg-gray-200 rounded-full mr-2 animate-pulse"></div>
      <div className="flex-1">
        <div className="w-32 h-4 bg-gray-200 rounded mb-2 animate-pulse"></div>
        <div className="w-full h-4 bg-gray-200 rounded mb-1 animate-pulse"></div>
        <div className="w-2/3 h-4 bg-gray-200 rounded animate-pulse"></div>
      </div>
    </div>
  </div>
);
