import { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import posthog from "posthog-js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import useAuth from "../../utils/useAuth";
import { useAppDispatch } from "../../store";
import { updateOrganizationKeyValue, uploadLogo } from "../../store/organization";
import apiClient from "../../utils/axiosInstance";
import SpinnerLoading from "../SpinnerLoading";
import AIModelsSection from "../AIModelsSection";
import Modal from "../Modal";
import NewPasswordModal from "../NewpasswordModal";

function AccountSetting({ userDetailsState, org }) {
    const { handleLogOut } = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [newPasswordModal, setNewPasswordModal] = useState(false);
    const [showFinalConfirmationModal, setShowFinalConfirmationModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);

    const handleLogoutFun = () => {
        handleLogOut();
    };
    const handleDeleteAccount = () => {
        setShowModal(true);
    };

    const confirmDeleteAccount = async () => {
        try {
            setIsLoading(true);
            const response = await apiClient().delete("/account");
            if (response.data && response.status === 200) {
                posthog.reset(true);
                dispatch(handleLogOut());
                navigate("/");
            }
        } catch (error) {
            toast.error("Error while deleting account");
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            if (newOrgName === org?.organizationName) {
                setIsEditing(false);
                return;
            }
            const response = await apiClient().put("/organization", {
                organizationName: newOrgName,
            })
            dispatch(updateOrganizationKeyValue({ key: "organizationName", value: response.data.organizationName }));
        } catch (error) {
            console.error("Error updating org name:", error);
        } finally {
            setIsEditing(false);
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsUploading(true);
        setError(null);

        const formData = new FormData();
        formData.append('logo', file);

        try {
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            const response = await apiClient().post('/logo', formData, config);
            dispatch(uploadLogo(response.data));
        } catch (err) {
            setError('Failed to upload logo. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const [newOrgName, setNewOrgName] = useState(org?.organizationName);
    const [primary, setPrimary] = useState(org?.primaryColor || "#0D2425");
    const [secondary, setSecondary] = useState(org?.secondaryColor || "#000000");


    const handlePrimaryColorChange = async () => {
        await apiClient().post("/color-config", {
            primaryColor: primary
        })
        dispatch(updateOrganizationKeyValue({ key: "primaryColor", value: primary }));
    };

    const handleSecondaryColorChange = async () => {
        await apiClient().post("/color-config", {
            secondaryColor: secondary
        })
        dispatch(updateOrganizationKeyValue({ key: "secondaryColor", value: secondary }));
    };

    return (
        <>
            {(isLoading || isUploading) && <SpinnerLoading isLoading={true} />}
            {(userDetailsState && org) &&
                <div className="bg-white rounded-lg w-full m-auto">
                    <div className="account-details">
                        <div>
                            <p className="text-lg font-normal mb-3">Account</p>
                        </div>
                        <div className="p-6 rounded-xl bg-backgroundColor">
                            <div className="flex items-center justify-between border-b-2 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary">Profile Picture</p>
                                <div className="flex">
                                    {userDetailsState?.userPictureUrl ? (
                                        <img
                                            src={userDetailsState.userPictureUrl}
                                            alt="Profile"
                                            className="rounded-full h-12 w-12 object-cover"
                                        />
                                    ) : (
                                        <div className=" text-white text-2xl rounded-full h-12 w-12 flex items-center justify-center cursor-pointer" style={{ backgroundColor: org?.secondaryColor }}>
                                            {userDetailsState?.name?.charAt(0).toUpperCase()}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center justify-between border-b-2 mt-5 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary">UserName</p>
                                <div className="flex">
                                    <p className="text-base font-normal text-textPrimary">
                                        {userDetailsState?.name}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center justify-between border-b-2 border-borderColor mt-5 pb-5">
                                <p className="text-lg font-normal text-secondary">Email</p>
                                <div className="flex">
                                    <p className="text-base font-normal text-textPrimary">
                                        {userDetailsState?.email}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center justify-between border-b-2 border-borderColor mt-5 pb-5">
                                <p className="text-lg font-normal text-secondary">Organization Name</p>
                                <div className="flex items-center">
                                    {isEditing ? (
                                        <>
                                            <input
                                                type="text"
                                                value={newOrgName}
                                                onChange={(e) => setNewOrgName(e.target.value)}
                                                className="text-base font-normal text-textPrimary mr-2 p-1 border rounded"
                                            />
                                            <Icon icon="pajamas:false-positive" className="cursor-pointer" width="1.2em" height="1.2em" onClick={handleSave} />
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-base font-normal text-textPrimary mr-2">
                                                {org?.organizationName}
                                            </p>
                                            <Icon icon="fa:edit" className="cursor-pointer" width="1.2em" height="1.2em" onClick={handleEdit} />
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center justify-between border-b-2 border-borderColor mt-5 pb-5">
                                <p className="text-lg font-normal text-secondary">Logo</p>
                                <div className="flex flex-col items-center">
                                    <div className="flex items-center">
                                        {(!org?.logoUrl && !org?.logoS3Loc) ? (
                                            <div className="mr-2">
                                                <p className="text-base font-normal text-textPrimary">
                                                    Upload your logo
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="mr-2">
                                                <p className="text-base font-normal text-textPrimary">
                                                    <img src={org?.logoUrl} alt="logo" className="h-12 w-28 object-cover" />
                                                </p>
                                            </div>
                                        )}
                                        <label htmlFor="logo-upload" className="cursor-pointer">
                                            <Icon
                                                icon="solar:upload-minimalistic-bold"
                                                className={`w-6 h-6 ${isUploading ? 'animate-pulse' : ''}`}
                                            />
                                            <input
                                                id="logo-upload"
                                                type="file"
                                                accept="image/*"
                                                className="hidden"
                                                onChange={handleFileUpload}
                                                disabled={isUploading}
                                            />
                                        </label>
                                    </div>
                                    {error && (
                                        <p className="text-red-500 text-sm mt-2">{error}</p>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center justify-between mt-5 pb-5 border-b-2 border-borderColor">
                                <p className="text-lg font-normal text-secondary">Created on</p>
                                <div className="flex">
                                    <p className="text-base font-normal text-textPrimary">
                                        {format(new Date(userDetailsState?.createdAt), "dd/MM/yyyy")}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center justify-between mt-5 pb-5">
                                <p className={`text-lg font-normal text-secondary`} >
                                    Primary Color
                                </p>
                                <div className="flex items-center">
                                    <p className="text-base font-normal text-textPrimary">
                                        <input type="color" value={primary} onChange={(e) => {
                                            setPrimary(e.target.value)
                                        }} />
                                    </p>
                                    <div className="icon text-lg" onClick={() => handlePrimaryColorChange()}>
                                        <Icon icon="mingcute:save-line" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between pb-5">
                                <p className="text-lg font-normal text-secondary">
                                    Secondary Color
                                </p>
                                <div className="flex items-center ">
                                    <p className="text-base font-normal text-textPrimary">
                                        <input type="color" value={secondary} onChange={(e) => {
                                            setSecondary(e.target.value)
                                        }} />
                                    </p>
                                    <div className="icon text-lg" onClick={() => handleSecondaryColorChange()}>
                                        <Icon icon="mingcute:save-line" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AIModelsSection organization={org} />
                    <div className="account-system mt-8">
                        <div>
                            <p className="text-lg font-normal mb-4">System</p>
                        </div>
                        <div className="md:p-6 p-2 rounded-xl bg-backgroundColor">
                            <div className="flex items-center justify-between border-b-2 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary">
                                    Active account
                                </p>
                                <div className="flex">
                                    <button
                                        onClick={handleLogoutFun}
                                        className="flex items-center py-2 px-4  bg-borderColor text-center text-md font-medium rounded-lg text-textPrimary"
                                    >
                                        Logout
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center  justify-between border-b-2 mt-5 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary">
                                    Delete account
                                </p>
                                <div className="flex">
                                    <button
                                        onClick={handleDeleteAccount}
                                        className="text-md font-normal bg-borderColor text-textPrimary py-2 px-4 rounded  w-full transition-colors duration-300"
                                    >
                                        Delete Account
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center justify-between mt-5 pb-5">
                                <p className="text-lg font-normal text-secondary">
                                    Update Password
                                </p>
                                <div className="flex">
                                    <button
                                        onClick={() => setNewPasswordModal(!newPasswordModal)}
                                        className=" text-md font-normal bg-borderColor text-textPrimary py-2 mb-4 px-4 rounded w-full transition-colors duration-300"
                                    >
                                        Update Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                confirmAction={confirmDeleteAccount}
                label={"Delete Account"}
                description={"Are you sure you want to delete your account?"}
                confirmText={"Confirm"}
                cancelText={"Cancel"}
            />

            <Modal
                showModal={showFinalConfirmationModal}
                setShowModal={setShowFinalConfirmationModal}
                confirmAction={confirmDeleteAccount}
                label="Confirm Account Deletion"
                description={`Are you sure you want to delete account?`}
                confirmText="Confirm"
                cancelText="Cancel"
            />

            <NewPasswordModal
                newPasswordModal={newPasswordModal}
                setNewPasswordModal={setNewPasswordModal}
            />
        </>
    );
}

export default AccountSetting;