import Person1 from "../assets/person1.png";
import Person2 from "../assets/person2.png";
import Person3 from "../assets/person3.png";
import Person4 from "../assets/person4.png";

export const benefits = [
  {
    icon: "typcn:messages",
    title: "Trustworthy Answers",
    description:
      "Unlike other AI tools, askFin ensures accuracy by citing sources and avoiding fictitious data, making verification straightforward.",
  },
  {
    icon: "carbon:document",
    title: "Create Company Reports",
    description:
      "Effortlessly generate comprehensive reports on companies, with all relevant data and citations.",
  },
  {
    icon: "heroicons:cpu-chip",
    title: "Real time Data",
    description:
      "Benefit from real-time financial information, unlike tools with outdated knowledge cutoffs.",
  },
  {
    icon: "fluent:clock-24-regular",
    title: "Save Time",
    description:
      "Get quick and detailed insights into any US public company, saving you valuable time.",
  },
  {
    icon: "fluent:brain-circuit-24-regular",
    title: "Memory",
    description:
      "Askfin remembers queries in a chat thread saving you from having to repeat information and makes future conversations more helpful.",
  },
  {
    icon: "heroicons:shield-check",
    title: "Secure",
    description:
      "Your data is hosted on secure servers with robust encryption, ensuring your data remains private and secure.",
  },
];

export const plans = [
  {
    name: "Premium",
    price: "$49",
    period: "month",
    features: [
      "100 credits per month",
      "Comprehensive Coverage: Includes access to SEC filings, earnings transcripts, market data, and more",
      "Up to 10 Document Uploads",
    ],
    buttonClass: "border-lendingButton text-lendingButton mt-3 font-bold",
    shadow: "h-[550px] shadow-md mt-5 md:mt-12 lg:mt-12",
  },
  {
    name: "Pro",
    price: "$99",
    period: "month",
    features: [
      "300 credits per month",
      "Comprehensive Coverage: Includes access to SEC filings, earnings transcripts, market data, and more",
      "Up to 30 Document Uploads",
    ],
    buttonClass: "bg-lendingButton text-white mt-3 font-normal",
    shadow: "h-[600px] border-lendingButton border-[1px] shadow-xl",
    isMostPopular: true,
  },
  {
    name: "Max",
    price: "$199",
    period: "month",
    features: [
      "Unlimited Credits",
      "Comprehensive Coverage: Includes access to SEC filings, earnings transcripts, market data, and more",
      "Up to 100 Document Uploads",
    ],
    buttonClass: "border-lendingButton text-lendingButton mt-5 font-bold",
    shadow: "h-[550px] shadow-md mt-5 md:mt-12 lg:mt-12",
  },
];

export const proPlans = [
  {
    name: "Premium",
    price: "$42",
    period: "per month",
    features: [
      "100 credits per month",
      "Comprehensive Coverage: Includes access to SEC filings, earnings transcripts, market data, and more",
      "Up to 10 Document Uploads",
    ],
    buttonClass: "border-lendingButton text-lendingButton mt-3 font-bold",
    shadow: "h-[550px] shadow-md mt-12",
  },
  {
    name: "Pro",
    price: "$83",
    period: "per month",
    features: [
      "300 credits per month",
      "Comprehensive Coverage: Includes access to SEC filings, earnings transcripts, market data, and more",
      "Up to 30 Document Uploads",
    ],
    buttonClass: "bg-lendingButton text-white mt-3 font-normal",
    shadow: "h-[600px] border-lendingButton border-[1px] shadow-xl",
    isMostPopular: true,
  },
  {
    name: "Max",
    price: "$167",
    period: "per month",
    features: [
      "Unlimited Credits",
      "Comprehensive Coverage: Includes access to SEC filings, earnings transcripts, market data, and more",
      "Up to 100 Document Uploads",
    ],
    buttonClass: "border-lendingButton text-lendingButton mt-5 font-bold",
    shadow: "h-[550px] shadow-md mt-12",
  },
];

export const testimonials = [
  {
    text: "Askfin has revolutionized the way I manage my business finances. The app’s intuitive interface and comprehensive financial insights have saved me countless hours. It’s like having a personal financial advisor in my pocket. Highly recommend it to any entrepreneur!",
    name: "John M.",
    role: "Small Business Owner",
    img: Person1,
  },
  {
    text: "As a freelancer, keeping track of my income and expenses used to be a nightmare. Askfin has simplified everything. I love the automatic categorization and the detailed reports that help me stay on top of my finances. It’s a must-have tool for anyone juggling multiple income streams.",
    name: "Jane D.",
    role: "Freelancer",
    img: Person2,
  },
  {
    text: "Askfin is a game-changer! The budgeting tools and real-time alerts have helped me stay on track with my savings goals. I also appreciate the investment recommendations which have significantly improved my portfolio performance. It’s the best financial app I’ve ever used.",
    name: "Alice B.",
    role: "Consultant",
    img: Person3,
  },
  {
    text: "Managing my money as a student was challenging until I discovered Askfin. The app’s easy-to-understand breakdowns and spending trackers have taught me how to budget effectively. I feel much more confident about my finances now. It’s an essential app for anyone starting their financial journey.",
    name: "Michael P.",
    role: "Entrepreneur",
    img: Person4,
  },
  {
    text: "Askfin has revolutionized the way I manage my business finances. The app’s intuitive interface and comprehensive financial insights have saved me countless hours. It’s like having a personal financial advisor in my pocket. Highly recommend it to any entrepreneur!",
    name: "John M.",
    role: "Small Business Owner",
    img: Person3,
  },
  {
    text: "Managing my money as a student was challenging until I discovered Askfin. The app’s easy-to-understand breakdowns and spending trackers have taught me how to budget effectively. I feel much more confident about my finances now. It’s an essential app for anyone starting their financial journey.",
    name: "Jane D.",
    role: "Freelancer",
    img: Person4,
  },
  {
    text: "As a freelancer, keeping track of my income and expenses used to be a nightmare. Askfin has simplified everything. I love the automatic categorization and the detailed reports that help me stay on top of my finances. It’s a must-have tool for anyone juggling multiple income streams.",
    name: "Alice B.",
    role: "Consultant",
    img: Person2,
  },
  {
    text: "Askfin is a game-changer! The budgeting tools and real-time alerts have helped me stay on track with my savings goals. I also appreciate the investment recommendations which have significantly improved my portfolio performance. It’s the best financial app I’ve ever used.",
    name: "Michael P.",
    role: "Entrepreneur",
    img: Person4,
  },
];

export const teamTabs = [
  {
    name: "Tagged Chat",
    icon: "ic:outline-chat",
    value: "taggedChat",
  },
  {
    name: "Members",
    icon: "heroicons-outline:user-group",
    value: "members",
  },
  {
    name: "Documents",
    icon: "mingcute:documents-line",
    value: "documents",
  },
];

export const allowedEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "ymail.com",
  "rocketmail.com",
  "outlook.com",
  "hotmail.com",
  "live.com",
  "msn.com",
  "aol.com",
  "icloud.com",
  "mail.com",
  "protonmail.com",
  "zoho.com",
  "gmx.com",
  "yandex.com",
  "tutanota.com",
  "fastmail.com",
  "yopmail.com",
];

export const contentArray = [
  {
    title: "Risk Disclosure",
    description: [
      `
      askfin is not an investment advisor or securities broker. All
      information provided herein is made to subscribers strictly
      for informational and educational purposes without regard to
      their individual investment goals, financial circumstances,
      investment knowledge and abilities or personal attitudes
      toward investing or trading. Investments in securities entail
      risk and are not suitable for all investors. None of the
      information contained on the askfin.ai website constitutes a
      recommendation that any particular security, portfolio,
      transaction, or investment strategy is suitable for any
      specific person. We do not advise you personally concerning the
      nature, potential, value or suitability of any particular
      security, portfolio, transaction, investment strategy or other
      matter.
    `,
    ],
  },
  {
    title: "Indemnification",
    description: [
      `
      You agree to defend, indemnify, and hold askfin, its
      officers, directors, employees, contractors and agents,
      harmless from and against any claims, liabilities,
      obligations, damages, losses, costs or debts, and expenses,
      including, without limitation, reasonable legal and accounting
      fees, arising out of or in any way connected with your access
      to or use of the Site, Services or askfin Content, or your
      violation of these Terms.
    `,
    ],
  },
  {
    title: "Subscription",
    description: [
      `
      All or some parts of the Service are made available on a paid
      subscription basis ("Subscriptions"). You will be billed in
      advance on a recurring and periodic basis ("Billing
      Cycle"). Billing cycles are set either on a monthly or annual
      basis, depending on the type of subscription plan you select
      when purchasing a Subscription.`,

      ` You agree that at the end of each Billing Cycle, your
      Subscription will automatically renew and your payment method
      for such Subscription will automatically be charged at the
      start of each new Subscription period for the fees and taxes
      applicable to that Billing Cycle, under the same conditions as
      the prior Billing Cycle unless you cancel it or askfin
      cancels it.`,

      ` A valid payment method, including credit card, is required to
      process the payment for your Subscription. You must provide
      askfin with accurate and complete billing information
      including full name, address, state, zip code, telephone
      number, and valid payment method information. By submitting
      such payment information, you automatically authorize askfin
      to charge all Subscription fees incurred through your account
      to any such payment instruments.`,

      `Should automatic billing fail to occur for any reason, askfin
      may attempt to charge the credit card on file one or more
      times. Should payment continue to fail, the Subscription will
      be cancelled. You may cancel your Subscription renewal through
      your online account page.
    `,
    ],
  },
  {
    title: "User Account",
    description: [
      `
      You are responsible for maintaining the confidentiality of
      your account and password, including but not limited to the
      restriction of access to your computer and/or account. You
      agree to accept responsibility for any and all activities or
      actions that occur under your account and/or password,
      whether your password is with our Service or a third-party
      service. You must notify us immediately upon becoming aware of
      any breach of security or unauthorized use of your account.
    `,
    ],
  },
  {
    title: "Third Party Software",
    description: [
      `
      The Services incorporate certain third party software ("Third
      Party Software"), which is licensed subject to the terms and
      conditions of the third party licensing such Third Party
      Software. Nothing in these Terms limits your rights under, or
      grants you rights that supersede, the terms and conditions of
      any applicable license for such Third Party Software.
    `,
    ],
  },
  {
    title: "Refunds",
    description: [
      `
      Payment obligations are non-cancelable, and fees paid are non-
      refundable and there are no credits for partially used
      Subscription periods. Certain refund requests for Subscriptions
      may be considered by askfin on a case-by-case basis and
      granted in the sole discretion of askfin.
    `,
    ],
  },
  {
    title: "Free Trial",
    description: [
      `
      askfin may, at its sole discretion, offer a Subscription with
      a free trial for a limited period of time ("Free Trial"). If you
      are on a Free Trial, you may cancel at any time until the last
      day of your Free Trial by following the cancellation
      procedures outlined in the Subscription section of these
      Terms. If you or askfin cancel your Free Trial, you
      acknowledge and agree that we may delete all of your Content
      or data that was assigned to your Free Trial.`,

      `At any time and without notice, askfin reserves the right
      to (a) modify the terms and conditions of any Free Trial offer,
      (b) cancel any Free Trial offer, or (c) cancel any Free Trial
      subscription at any time.
    `,
    ],
  },
  {
    title: "Prohibited Activities",
    description: [
      `  You may not access or use the Site for any purpose other than
      that for which we make the Site available. The Site may not be
      used in connection with any commercial endeavors except those
      that are specifically endorsed or approved by us.`,

      `You agree not to do any of the following while using the Site,
      Services or askfin content:`,

      `- duplicate, decompile, reverse engineer, disassemble or
      decode the Service (including any underlying idea or
      algorithm), or attempt to do any of the same;`,

      ` - access or use the Service in any manner that could disable,
      overburden, damage, disrupt or impair the Service or interfere
      with any other party's access to or use of the Service or use
      any device, software or routine that causes the same;`,

      ` - attempt to gain unauthorized access to, interfere with,
      damage or disrupt the Service, accounts registered to other
      users, or the computer systems or networks connected to the
      Service;`,

      ` - circumvent, remove, alter, deactivate, degrade or thwart any
      technological measure or content protections of the Service;`,

      ` - use any robot, spider, crawlers or other automatic device,
      process, software or queries that intercepts, "mines,"
      scrapes or otherwise accesses the Service to monitor, extract,
      copy or collect information or data from or through the
      Service, or engage in any manual process to do the same;`,

      `    - access or use the Service in any way not expressly permitted
      by these Terms.`,

      `askfin reserves the right, at any time and without prior
      notice, to remove or disable access to any askfin Content and
      any User Content, that askfin, in its sole discretion,
      considers to be in violation of these Terms or otherwise
      harmful to the Site or Services.`,
    ],
  },
  {
    title: "Termination",
    description: [
      `
      We may terminate or suspend your account and bar access to the
      Service immediately, without prior notice or liability, under
      our sole discretion, for any reason whatsoever and without
      limitation, including but not limited to a breach of the
      Terms.`,

      `You may delete your account at any time from within your
      account page. Upon which, all your Content will be immediately
      deleted from our servers and can no longer be recovered.`,

      `All provisions of the Terms which by their nature should
      survive termination shall survive termination, including,
      without limitation, ownership provisions, warranty
      disclaimers, indemnity and limitations of liability.
    `,
    ],
  },
  {
    title: "Limitation of Liabilities",
    description: [
      `
      IN NO EVENT SHALL ASKFIN, NOR ITS DIRECTORS, EMPLOYEES,
      PARTNERS, AGENTS, CONTRACTORS, OR AFFILIATES, BE LIABLE FOR
      ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE
      DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA,
      USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (A)
      YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE
      SERVICE; (B) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE
      SERVICE; (C) ANY CONTENT OBTAINED FROM THE SERVICE; AND (D)
      UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS
      OR CONTENT, WHETHER BASED ON WARRANTY, CONTRACT,
      TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, WHETHER
      OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH
      DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE
      FAILED OF ITS ESSENTIAL PURPOSE.
    `,
    ],
  },
  {
    title: "Disclaimer",
    description: [
      `
      YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
      PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE SERVICE IS
      PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
      IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-
      INFRINGEMENT OR COURSE OF PERFORMANCE`,

      `ASKFIN, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS
      DO NOT WARRANT THAT (A) THE SERVICE WILL FUNCTION
      UNINTERRUPTED, SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR
      LOCATION; (B) ANY ERRORS OR DEFECTS WILL BE CORRECTED; (C) THE
      SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D)
      THE RESULTS OF USING THE SERVICE WILL MEET YOUR REQUIREMENTS.
    `,
    ],
  },
  {
    title: "Changes",
    description: [
      `
      We reserve the right, at our sole discretion, to modify,
      discontinue or terminate the site or Services or to modify
      these Terms at any time and for any reasons. If we modify these
      Terms, we will update the “Last updated” date of these Terms
      of use, and you waive any right to receive specific notice of
      each such change. It is your responsibility to periodically
      review these Terms of use to stay informed of updates. By
      continuing to access or use our Service after any revisions
      become effective, you agree to be bound by the revised terms.
    `,
    ],
  },
  {
    title: "Entire Agreement",
    description: [
      `
      These Terms, together with our Privacy Policy, constitute the
      entire agreement between us regarding our Service and
      supersede and replace any prior agreements we might have had
      between us regarding the Service.
    `,
    ],
  },
  {
    title: "Contact Us",
    description: [
      `
      If you have any comments or questions regarding these Terms,
      please contact askfin by email at support@askfin.ai.
    `,
    ],
  },
];

export const privacyPolicy = [
  {
    title: "Information we collect",
    description: [
      {
        title: "A. Personal Contact Information,",
        des: [
          "When you create an account or otherwise use the Services, we collect information such as your name and email address (“Personal Contact Information”).",
        ],
      },
      {
        title: "B. Personal Contact Information,",
        des: [
          "We use third-party payment providers to process payments on the Services. We may receive information associated with your payment information, such as billing address and transaction information (“Billing Information”), but we do not directly store payment information on the Services.",
        ],
      },
      {
        title: "C. Personal Contact Information,",
        des: [
          "As you use our Services, askfin may also collect information through the use of frequently used information-gathering tools, such as cookies and Web beacons (“Website Navigational Information”). Website Navigational Information includes standard information from your web browser (such as browser type and browser language), your Internet Protocol (“IP”) address, and the actions you take on AskFin’s website (such as web pages viewed and links clicked).",
          "We may use third parties, such as GoogleAnalytics or other analytics providers, to analyze traffic to our Site. They have their own privacy policies addressing how they use the analytics and other information and we do not have access to, nor control over, third parties’ use of cookies or other tracking technologies.",
        ],
      },
    ],
  },
  {
    title: "Sharing & Disclosure of your information",
    description: [
      {
        title: "A. Sale of Personal Information",
        des: [
          "askfin does not sell, rent, or trade your private information to any third parties in any way.",
        ],
      },
      {
        title: "B. Service Providers and Business Partners",
        des: [
          "askfin may use certain trusted third party companies and individuals to help us provide, analyze, and improve the Services (including, but not limited to, data storage, maintenance services, database management, web analytics, payment processing, and improvement of the Services’ features)",
        ],
      },
      {
        title:
          "C. Disclosure in the Event of Merger, Sale, or Other Asset Transfer",
        des: [
          "If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to another provider, then your information may be sold or transferred as part of such a transaction, as permitted by law and/or contract.",
        ],
      },
    ],
  },
  {
    title: "Changes to our Privacy Policy",
    description:
      "We may revise this Privacy Policy from time to time at our sole discretion. Accordingly, when you use any of our Services, you should check the date of this Policy and review any changes since you last reviewed the Policy. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if you continue to use the Services after the new Privacy Policy takes effect.",
  },
  {
    title: "Contact",
    description:
      "If you have any questions regarding this Policy, please contact us at support@askfin.ai",
  },
];

export const termsContent = [
  {
    text: `These Terms of Service of Hensu LLC, doing business as askfin
    (“askfin”, "we," "our," or "us") are an agreement that
    describes your rights and responsibilities as a askfin
    customer, concerning your access to and use of the
    www.askfin.ai website as well as any other media form, media
    channel, media website or mobile application related, linked,
    or otherwise connected thereto (collectively, the “Site”).`,
  },
  {
    text: `You agree that by accessing the Site, you have read,
    understood and accepted the terms of this Terms of Service
    Agreement (this “Agreement” or these “Terms”) by signing up
    for an askfin account through the mechanism provided. Please
    read these terms carefully before using the Services. IF YOU
    DO NOT AGREE TO BE BOUND BY THESE TERMS, YOU SHOULD NOT USE
    THE SERVICE.`,
  },
  {
    text: `If you are using the Services on behalf of an organization,
    you are agreeing to these Terms for that organization and
    promising that you have the authority to bind that
    organization to these terms. In that case, “you” and “your”
    will refer to that organization.`,
  },
];

export const privacyContent = [
  {
    text: `At Hensu LLC (“AskFin”, "we, " "our, " or "us"), we take your
    privacy and the security of your information very seriously.
    This Privacy Policy (“Privacy Policy”) covers the personal
    information we collect about you when you use our products or
    services, or otherwise interact with us, including on our
    website at https://www.askfin.ai, and our related online and
    offline offerings (collectively, the “Services”). This Privacy
    Policy is incorporated into, and is part of, our Terms of
    Service available at https://www.askfin.ai/terms-of-service which govern
    your access to the Services.`,
  },
  {
    text: `This Privacy Policy (“Privacy Policy”) is designed to help you
    understand how we collect, use and share your personal
    information and to assist you in exercising the privacy rights
    available to you. By using our Service, you consent to the
    collection, transfer, processing, storage, disclosure and
    other uses of your information described in this Privacy
    Policy. If you do not agree to the terms of this Policy,
    please do not use any of our services.`,
  },
];
