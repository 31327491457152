import { Icon } from "@iconify/react/dist/iconify.js";
import Logo from "../assets/logo.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import InviteModal from "./sidebar/InviteModal";
import useAuth from "../utils/useAuth";
import { getUserDetails } from "../store/authSlice";
import debounce from "lodash/debounce";
import apiClient from "../utils/axiosInstance";
import { format } from "date-fns";
import { getOrganization } from "../store/organization";
import quoromlogo from "../assets/logo-quorom.png";


function DashboardHeader() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const dropdownRef = useRef(null);
  const [inviteModule, setInviteModule] = useState(false);
  const { userDetail, organizationState, organizationStatus } = useAppSelector((state) => ({
    userDetail: state.auth.userDetails,
    organizationStatus: state.organization.organizationStatus,
    organizationState: state.organization.organization,
  }));

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (user?.userId && !userDetail) {
      dispatch(getUserDetails(user.userId));
    }
  }, [user, userDetail, dispatch]);


  useEffect(() => {
    if (organizationState?.length === 0) {
      dispatch(getOrganization());
    }
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleButtonClick = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  const debouncedSearch = useRef(
    debounce(async (query) => {
      if (query.length > 0) {
        setIsLoading(true);
        try {
          const response = await apiClient().post("/search", { query });
          setSearchResults(response.data.search_results || []);
        } catch (error) {
          console.error("Search error:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setSearchResults([]);
      }
    }, 300)
  ).current;

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm]);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchResultClick = (item) => {
    switch (item.searchType) {
      case "thread":
        if (item.type === "thread") {
          navigate(`/thread/${item.threadId}`);
        } else {
          navigate(`/answer-search/${item.threadId}`);
        }
        break;
      case "team":
        navigate(`/team/${item.teamId}`);
        break;
      case "user":
        navigate(`/dm/${item.userId}`);
        break;
      default:
        console.error("Unknown search result type:", item.searchType);
    }
    setSearchTerm("");
    setSearchResults([]);
  };

  return (
    <>
      {organizationState?.length > 0 && <div>
        <header className="flex items-center justify-between px-4 py-2 w-full"
          style={{ backgroundColor: organizationState[0]?.primaryColor }}>
          {organizationStatus === "loading" ? (
            <div className="logo-container flex items-center md:w-1/4 w-2/12">
            </div>
          ) : organizationState[0]?.logoS3Loc ? (
            <div className="logo-container flex items-center cursor-pointer md:w-1/4 w-2/12">
              <p className="text-2xl font-semibold text-white hidden md:block">
                <img src={organizationState[0]?.logoUrl} alt="logo" className="h-12 w-28 object-cover" />
              </p>
            </div>
          ) : (
            <div className="logo-container flex items-center cursor-pointer md:w-1/4 w-2/12">
              <img src={quoromlogo} alt="logo" className="h-12 w-28 object-cover" />
            </div>
          )}
          <div className="search-container flex-grow flex justify-center md:w-3/4 w-2/3">
            <div className="input-container w-full relative">
              <input
                type="text"
                placeholder="Search threads, spaces, or members"
                className="pl-10 pr-4 py-2 rounded-full  w-full bg-[#FFFFFF21] text-white placeholder-white border-none focus:border-none focus:ring-0 focus:outline-none"
                value={searchTerm}
                onChange={handleSearchInputChange}
              />
              <Icon
                icon="tabler:search"
                width="24px"
                height="24px"
                className="absolute left-3 top-2.5 h-5 w-5 text-white"
              />
              {(isLoading || searchResults.length > 0) && (
                <div className="absolute z-10 w-full mt-2 bg-white rounded-md shadow-lg max-h-96 overflow-y-auto">
                  {isLoading ? (
                    <div className="p-4">
                      <div className="animate-pulse flex space-x-4">
                        <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                        <div className="flex-1 space-y-6 py-1">
                          <div className="h-2 bg-slate-200 rounded"></div>
                          <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                              <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-200 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : searchResults.length > 0 ? (
                    searchResults.map((item, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
                        onClick={() => handleSearchResultClick(item)}
                      >
                        <div className="flex items-center w-full">
                          <Icon
                            icon={
                              item.searchType === "thread"
                                ? "heroicons:chat-bubble-left-ellipsis"
                                : item.searchType === "team"
                                  ? "mdi:account-group-outline"
                                  : "mdi:account-outline"
                            }
                            className="mr-3 text-gray-600 flex-shrink-0"
                            width="24"
                            height="24"
                          />
                          <div className="flex-grow truncate">
                            <p className="font-semibold truncate py-1">
                              {item.searchType === "thread"
                                ? `${item.chatName}`
                                : item.searchType === "team"
                                  ? item.teamName
                                  : item.name}
                            </p>
                          </div>
                          {item.searchType === "user" && (
                            <span
                              className={`h-3 w-3 rounded-full ${item?.status === "online" ? "bg-green-500" : "bg-gray-500"
                                }`}
                            ></span>
                          )}
                          <p className="text-sm text-gray-600 ml-3 flex-shrink-0">
                            {format(new Date(item.createdAt), "d MMM")}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="p-2">No results found</div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="profile-container flex md:items-center justify-end md:w-1/4 w-2/12">
            <div className="relative inline-block" ref={dropdownRef}>
              <button
                onClick={toggleDropdown}
                className="w-8 h-8 rounded-full focus:outline-none"
              >
                {userDetail?.userPictureUrl ? (
                  <img
                    src={userDetail.userPictureUrl}
                    alt="Profile"
                    className="rounded-full object-cover"
                  />
                ) : (
                  <div className=" text-white text-2xl rounded-full flex items-center justify-center cursor-pointer" style={{ backgroundColor: organizationState[0]?.secondaryColor }}>
                    {userDetail?.name?.charAt(0).toUpperCase()}
                  </div>
                )}
              </button>
              {isOpen && (
                <div className="absolute right-0 z-[12] mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg">
                  <ul>
                    <button
                      className="px-2 py-2 flex items-center w-48 cursor-pointer"
                      onClick={() => handleButtonClick("/account")}
                      onMouseEnter={() => setHoveredButton('account')}
                      onMouseLeave={() => setHoveredButton(null)}
                      style={{
                        backgroundColor: hoveredButton === 'account' && organizationState[0]?.secondaryColor,
                        transition: 'background-color 0.3s',
                      }}
                    >
                      <Icon
                        icon="ant-design:setting-outlined"
                        width="24px"
                        height="24px"
                        className="mr-2"
                      />
                      Account
                    </button>
                    <button
                      className="px-2 py-2 flex items-center w-48 cursor-pointer"
                      onClick={() => setInviteModule(true)}
                      onMouseEnter={() => setHoveredButton('invite')}
                      onMouseLeave={() => setHoveredButton(null)}
                      style={{
                        backgroundColor: hoveredButton === 'invite' && organizationState[0]?.secondaryColor,
                        transition: 'background-color 0.3s',
                      }}
                    >
                      <Icon
                        icon="bx:user-plus"
                        width="24px"
                        height="24px"
                        className="mr-2"
                      />
                      Invite a colleague
                    </button>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </header>
      </div>}
      <InviteModal
        inviteModule={inviteModule}
        setInviteModule={setInviteModule}
      />
    </>
  );
}

export default DashboardHeader;