import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import { changeModel, updateKey } from '../store/aiModelSlice';
import { toast } from 'react-toastify';
import apiClient from '../utils/axiosInstance';

const AIModelsTable = ({ modelState, userDetails }) => {
    const dispatch = useDispatch();
    const [showKeys, setShowKeys] = useState({});
    const [editingProviderId, setEditingProviderId] = useState(null);
    const [editableProviderKey, setEditableProviderKey] = useState('');
    const [isKeyValid, setIsKeyValid] = useState(true);

    const toggleShowKey = (providerId) => {
        setShowKeys(prev => ({ ...prev, [providerId]: !prev[providerId] }));
    };

    const handleModelChange = async (providerId, newModel) => {
        const res = await apiClient().post("/change-model", {
            providerId,
            model: newModel
        });
        dispatch(changeModel({ providerId, models: res.data.models }));
    };

    const handleEditClick = (provider) => {
        setEditingProviderId(provider.modelId);
        setEditableProviderKey(provider.provider_key);
        setIsKeyValid(true);
    };

    const verifyGeminiApiKey = async (apiKey) => {
        const API_VERSION = 'v1';
        const apiUrl = `https://generativelanguage.googleapis.com/${API_VERSION}/models?key=${apiKey}`;
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            muteHttpExceptions: true,
        });
        return response.status === 200;
    };

    const verifyOpenaiApiKey = async (apiKey) => {
        const apiUrl = `https://api.openai.com/v1/models`;
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiKey}` },
            muteHttpExceptions: true,
        });
        return response.status === 200;
    };

    const handleSaveClick = async () => {
        const provider = modelState.providers.find(p => p.modelId === editingProviderId);
        if (!provider) return;

        if (provider.provider_name === 'openai') {
            const isValid = await verifyOpenaiApiKey(editableProviderKey);
            setIsKeyValid(isValid);
            if (!isValid) {
                toast.error('Invalid OpenAI API key');
                return;
            }
        } else if (provider.provider_name === 'gemini') {
            const isValid = await verifyGeminiApiKey(editableProviderKey);
            setIsKeyValid(isValid);
            if (!isValid) {
                toast.error('Invalid Gemini API key');
                return;
            }
        }

        if (editableProviderKey !== provider.provider_key) {
            try {
                await apiClient().put(`/provider/${editingProviderId}`, {
                    providerKey: editableProviderKey,
                });
                dispatch(updateKey({ providerId: editingProviderId, key: editableProviderKey }));
            } catch (error) {
                toast.error(`Error updating provider key: ${error}`);
            }
        }
        setEditingProviderId(null);
    };

    const handleKeyChange = (e) => {
        setEditableProviderKey(e.target.value);
        setIsKeyValid(true);
    };

    const showModelsForProvider = (providerName) => {
        return ['openai', 'gemini', 'anthropic'].includes(providerName.toLowerCase());
    };

    const getCurrentModelForProvider = (providerId) => {
        const models = modelState.models[providerId];
        if (!models) return null;
        return models.find(model => model.currentUsed)?.model || null;
    };

    return (
        <div className="overflow-x-auto mt-8">
            <table className="min-w-full bg-white border border-gray-300 table-fixed">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-4 py-2 border-b w-1/4">Provider Name</th>
                        <th className="px-4 py-2 border-b w-1/4">Provider Key</th>
                        <th className="px-4 py-2 border-b w-1/4">Models</th>
                        {(userDetails?.role === "admin" || userDetails?.role === "super admin") && <th className="px-4 py-2 border-b w-1/4">Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {modelState?.providers?.map((provider) => (
                        <tr key={provider.modelId} className="border-b">
                            <td className="px-4 py-2 text-center">
                                {provider.provider_name}
                            </td>
                            <td className="px-4 py-2 text-center">
                                {editingProviderId === provider.modelId ? (
                                    <input
                                        type="text"
                                        value={editableProviderKey}
                                        onChange={handleKeyChange}
                                        className={`border p-1 rounded w-full max-w-[150px] overflow-x-auto ${!isKeyValid ? 'border-red-500' : ''}`}
                                    />
                                ) : (
                                    <div className="max-w-[150px] overflow-x-auto m-auto whitespace-nowrap">
                                        {showKeys[provider.modelId] ? provider.provider_key : '*'.repeat(20)}
                                    </div>
                                )}
                            </td>
                            <td className="px-4 py-2 text-center">
                                {showModelsForProvider(provider.provider_name) && (
                                    <select
                                        onChange={(e) => handleModelChange(provider.modelId, e.target.value)}
                                        value={getCurrentModelForProvider(provider.modelId) || ''}
                                        className="border rounded p-1 w-full"
                                    >
                                        {modelState.models[provider.modelId]?.map((model) => (
                                            <option key={model.model} value={model.model}>
                                                {model.model}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </td>
                            {(userDetails?.role === "admin" || userDetails?.role === "super admin") &&
                                <td className="px-4 py-2 text-center">
                                    <button onClick={() => toggleShowKey(provider.modelId)} className="mr-2">
                                        <Icon icon={showKeys[provider.modelId] ? "mdi:eye-off" : "mdi:eye"} width="1.2em" height="1.2em" />
                                    </button>
                                    {editingProviderId === provider.modelId ? (
                                        <button onClick={handleSaveClick}>
                                            <Icon icon="mdi:check" width="1.2em" height="1.2em" />
                                        </button>
                                    ) : (
                                        <button onClick={() => handleEditClick(provider)}>
                                            <Icon icon="mdi:pencil" width="1.2em" height="1.2em" />
                                        </button>
                                    )}
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AIModelsTable;