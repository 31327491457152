import { useEffect, useState } from "react";
import { getTeams } from "../../store/teamSlice";
import AllMembersModal from "./AllMemberModal";
import { useAppDispatch, useAppSelector } from "../../store";

const MemberSkeleton = () => (
  <div className="flex items-center">
    {[...Array(3)].map((_, index) => (
      <div key={index} className="-ml-2 first:ml-0">
        <div className="w-8 h-8 rounded-full bg-gray-300 animate-pulse"></div>
      </div>
    ))}
  </div>
);

function Members({ teamIds, setInviteModule, isAnotherspace = false, team = {}, setIsAnotherspace }) {
  const dispatch = useAppDispatch();
  const [showAllMembers, setShowAllMembers] = useState(false);
  const { teamState, teamStatus, organizationState } = useAppSelector((state) => ({
    teamState: state?.teams?.teams,
    teamStatus: state.teams.teamStatus,
    organizationState: state.organization.organization,
  }));

  useEffect(() => {
    if (!teamState?.length > 0 && !isAnotherspace) {
      dispatch(getTeams());
    }
  }, []);

  const relevantTeams = teamState?.filter((team) => teamIds?.includes(team.teamId)) || [];

  const allMembers = isAnotherspace ? team.members : relevantTeams.flatMap((team) => team.members || []);

  const uniqueMembers = Array.from(
    new Map(allMembers.map((member) => [member.email, member])).values()
  );

  const displayedMembers = uniqueMembers.slice(0, 3);
  const remainingCount = uniqueMembers.length - displayedMembers.length;

  if (teamStatus === "loading") {
    return (
      <div className={`flex-1 ml-60 md:mt-0`}>
        <div className="flex items-center justify-between border-borderColor">
          <MemberSkeleton />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center justify-between border-borderColor cursor-pointer">
        <div
          className="flex items-center"
          onClick={() => {
            setShowAllMembers(true);
          }}
        >
          {displayedMembers.map((member, index) => (
            <div key={member.email} className="-ml-2 first:ml-0 relative">
              <div className="w-8 h-8 rounded-full  flex items-center justify-center text-white font-semibold"
                style={{
                  backgroundColor: organizationState[0]?.primaryColor,
                }}
              >
                {member.email[0].toUpperCase()}
              </div>
              <div
                className={`absolute -top-1 right-1 w-3 h-3 rounded-full border-2 border-white ${member.user_status === "online"
                  ? "bg-green-500"
                  : "bg-gray-400"
                  }`}
              ></div>
            </div>
          ))}
          {remainingCount > 0 && (
            <div
              className="-ml-2 w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center text-gray-600 font-semibold cursor-pointer"
              onClick={() => setShowAllMembers(true)}
            >
              +{remainingCount}
            </div>
          )}
        </div>
      </div>
      {teamIds?.length > 0 && (
        <AllMembersModal
          isAnotherspace={isAnotherspace}
          team={team}
          setIsAnotherspace={setIsAnotherspace}
          teamId={teamIds[0]}
          members={uniqueMembers}
          showAllMembers={showAllMembers}
          setShowAllMembers={setShowAllMembers}
          setInviteModule={setInviteModule}
        />
      )}
    </>
  );
}

export default Members;
