import React from "react";
import quoromlogo from "../../assets/logo-quorom.png";

function Footer() {

  return (
    <div className="footer bg-[#D3CCF8] py-20 flex flex-col gap-8 items-center">
      <div className="logo ">
        <img src={quoromlogo} alt="" className="max-w-[150px]" />
      </div>
      <div className="links flex items-center gap-4">
        <a href="#" className="text-sm">
          About
        </a>
        <a href="#" className="text-sm">
          Features
        </a>
        <a href="#" className="text-sm">
          Pricing
        </a>
      </div>
      <div className="copyright">
        <p className="text-sm text-[#7E7E7E]">Copyright © 2024 I Quorom.ai</p>
      </div>
    </div>
  );
}
export default Footer;
