import ChatAnswer from "../Components/ChatAnswer";
import { useEffect, useRef, useState } from "react";
import SpinnerLoading from "../Components/SpinnerLoading";
import Sidebar from "../Components/Sidebar";
import { useParams } from "react-router-dom";
import ChatLoader from "../Components/ChatLoader";
import ChatBox from "../Components/ChatBox";
import { useAppDispatch, useAppSelector } from "../store";
import { clearChatQueries, getChatsByChatId } from "../store/finChat";
import useAuth from "../utils/useAuth";
import DashboardSidebar from "./DashboardSidebar";

function AnswerSearch({ closeSideBar }) {
  const { user } = useAuth();
  const params = useParams();
  const dispatch = useAppDispatch();
  const lastNodeRef = useRef();
  const id = params.id;
  const { chatQueries, status } = useAppSelector(
    (state) => ({
      chatQueries: state?.finChat?.chatQueries,
      status: state?.finChat?.chatStatus,
    })
  );
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const questions = chatQueries?.chat?.queries
    ?.slice(-5)
    ?.map((answer) => answer.query);

  useEffect(() => {
    const getQueries = async () => {
      dispatch(getChatsByChatId(id));
    };
    if (user) {
      getQueries();
    }
  }, [user]);

  useEffect(() => {
    return () => {
      dispatch(clearChatQueries());
    };
  }, []);

  useEffect(() => {
    if (lastNodeRef.current) {
      lastNodeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatQueries, question]);


  return (
    <>
      {(status === "loading" || isLoading) && (
        <SpinnerLoading isLoading={true} />
      )}
      <div>
        <div className="flex md:flex-row flex-col">
          <DashboardSidebar />
          <Sidebar />
          <div
            className={`bg-backgroundColor header-height flex justify-center items-center w-full`}
          >
            <div className="rounded-lg sm:w-full md:w-full lg:w-full">
              <div className="relative">
                <div className="header-height overflow-y-hidden lg:h-[100%] xl:h-[100vh] w-full">
                  <div
                    className={`grid grid-cols-1 gap-4`}
                  >
                    <div
                      className={`col-span-1 lg:col-span-3 border-r-2 w-full border-borderColor header-height overflow-y-scroll flex flex-col justify-between`}
                    >
                      <div
                        className={`${!chatQueries?.chat?.queries.length > 0
                          ? "header-height"
                          : ""
                          }`}
                      >
                        {chatQueries?.chat?.queries?.map((item, index, array) => {
                          const isLastIndex = index === array.length - 1;

                          return (
                            <div key={item?.messageId}>
                              <ChatAnswer
                                messageId={item?.messageId}
                                setIsChatLoading={setIsChatLoading}
                                setIsLoading={setIsLoading}
                                answerChat={item}
                                threadId={id}
                                setQuestion={setQuestion}
                                isLastIndex={isLastIndex}
                                closeSideBar={closeSideBar}
                              />
                            </div>
                          );
                        })}
                      </div>
                      {question && (
                        <div className="px-4 py-2 break-words mt-5 mb-8">
                          <p className="text-lg font-normal text-textPrimary">
                            {question}
                          </p>
                        </div>
                      )}
                      {isChatLoading && <ChatLoader />}
                      <div ref={lastNodeRef}></div>
                      <ChatBox
                        setIsChatLoading={setIsChatLoading}
                        threadId={id}
                        setQuestion={setQuestion}
                        questions={questions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnswerSearch;