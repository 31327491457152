import { Icon } from "@iconify/react/dist/iconify.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { dashboardActiveTab } from "../store/sidebarSlice";
import { useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";

const sidebar = [
  {
    name: "Home",
    path: "/dashboard",
    icon: "heroicons:home",
  },
  {
    name: "Threads",
    icon: "heroicons:chat-bubble-left-ellipsis",
  },
  {
    name: "Starred",
    icon: "heroicons:star",
  },
  {
    name: "DMs",
    icon: "heroicons:chat-bubble-left-right",
  },
  {
    name: "Space",
    icon: "heroicons:user-group",
  },
  {
    name: "Files",
    icon: "heroicons:document-duplicate",
  },
];
function DashboardSidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [hoveredIndex, setHoveredIndex] = useState(null);


  const { dashboardActiveTabState, organizationState, userDetailsState } = useAppSelector((state) => ({
    dashboardActiveTabState: state.sidebar.dashboardActiveTab,
    organizationState: state.organization.organization,
    userDetailsState: state.auth.userDetails
  }));

  const handleNavigation = (item) => {
    dispatch(dashboardActiveTab(item.name));
    if (item.name === "Home") {
      navigate(item.path);
    }
    if (["Threads", "DMs", "Files", "Space", "Starred"].includes(item.name)) {
      navigate(`${location.pathname}`, {
        state: {
          isFirstTime: true
        }
      });
    }
  };

  return (
    <>
      {(userDetailsState && organizationState.length > 0) ?
        <div className={`dashboard-sidebar flex  md:flex-col justify-between header-height md:w-20 px-2 py-3 mypadding`} style={{ backgroundColor: organizationState[0]?.primaryColor }}>
          <div className="dashboard-icon-container flex md:flex-col md:items-center">
            {sidebar.map((item, index) => (
              <div
                key={index}
                className={`relative flex w-full items-center justify-center rounded-md p-2 mb-2`}
                onClick={() => handleNavigation(item)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                style={{
                  backgroundColor:
                    dashboardActiveTabState === item.name || hoveredIndex === index
                      ? organizationState[0]?.secondaryColor
                      : 'transparent',
                  transition: 'background-color 0.3s',
                }}
              >
                <button className="text-white flex items-center flex-col justify-center">
                  <Icon icon={item.icon} width="1.5em" height="1.5em" />
                  <p className="text-xs font-normal text-center">{item.name}</p>
                </button>
              </div>
            ))}
          </div>
          <div className="md:flex hidden md:items-center md:justify-center md:rounded-md md:p-2"
          >
            <button
              className="text-white flex items-center flex-col justify-center"
              onClick={() => navigate("/dashboard")}
            >
              <Icon icon="heroicons:pencil-square" width="1.8em" height="1.8em" />
            </button>
          </div>
        </div>
        :
        <div className="spinner h-screen flex items-center justify-center">
          <PuffLoader
            color={userDetailsState?.primaryColor || organizationState[0]?.primaryColor}
            cssOverride={null}
            loading
            size={60}
          />
          <h1 className="text-3xl ms-3 font-bold">Loading...</h1>
        </div>
      }
    </>
  );
}

export default DashboardSidebar;